import React, { useState } from 'react'
import { tokens } from '@equinor/eds-tokens'
import styled from 'styled-components'
import { folder, arrow_drop_down, external_link } from '@equinor/eds-icons'
import { Icon } from '@equinor/eds-core-react'
import { Tooltip } from '@equinor/eds-core-react'

Icon.add({ folder })

const Folder = styled.div`
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 52px;

    & div {
        display: inherit;
        align-items: inherit;
    }
`

const FolderBody = styled.div`
    padding: 5px;
`


const StyledP = styled.p`
        display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0 5px 10px;
    margin: 0;
    color: black;
    font-weight: 600;
    line-height: 1.3em; 
    max-height: 4.2em; 

    ${props =>
    !props.external &&
        `
        &:hover {
            color: ${tokens.colors.interactive.primary__resting.rgba};
        }
    `}
`

const FolderIcon = styled(Icon)`
    margin-right: 3px;
    position: relative;
    top: 3px;
`
const Count = styled.p`
    margin: 0 10px;
    opacity: 0.7;
    font-size: 11px;
`
const IconWrapper = styled.div`
    visibility: ${props => (props.isHovered ? 'visible' : 'hidden')};
    position: relative;
    top: 6px;
    padding: 5px 5px 0 10px;
`

interface Props {
    name: string
    onClick: () => void
    count?: string
    external?: boolean
    active?: boolean
}

const FolderElement = ({ name, onClick, count, external }: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <Tooltip title={name} placement="top">
      <Folder onClick={onClick} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <FolderBody>
          <div>
            <FolderIcon data={folder} size={25} />
          </div>
          <div>
            <StyledP external={external}>{name}</StyledP>
            {count && <Count>{count}</Count>}
          </div>
        </FolderBody>

        {external && (
          <IconWrapper isHovered={isHovered}>
            <Icon data={external_link} size={16} />
          </IconWrapper>
        )}
      </Folder>
    </Tooltip>
  )
}

export default FolderElement