import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, Icon, Typography } from '@equinor/eds-core-react'
import { close } from '@equinor/eds-icons'
import { ViewContext } from '../../../contexts/ViewContext'
import SidebarFilters from '../../inputs/SidebarFilters'

interface ContainerProps {
    width: number
}

const Container = styled.div<ContainerProps>`
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 50px;
    border-right: 1px solid #e0e0e0;
    min-width: 500px;
    max-width: 500px;
    flex-grow: 1;
`

const CloseButton = styled(Button)``

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px;
    margin-top: 10px;
    border-bottom: 1px solid #e0e0e0;
`

const HeaderText = styled(Typography)`
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: -7px;
`

interface Props {
    clearSearch: () => void
    isInAdmin: boolean
}

const FolderNavigation: React.FC<Props> = ({ clearSearch, isInAdmin }) => {

  const { sideBarIsOpen, setSideBarIsOpen, sideBarMode } = useContext(ViewContext)

  const getHeaderText = () => {
    if (sideBarMode === 'filter') {
      return 'Filter Results'
    } else if (sideBarMode === 'navigate') {
      return 'Folder Navigation'
    }
  }

  return (
    <Container className="folderNavigation" style={{ display: sideBarIsOpen ? 'flex' : 'none' }}>
      <Header>
        <HeaderText variant="h3">{getHeaderText()}</HeaderText>
        <CloseButton variant="ghost" onClick={() => setSideBarIsOpen(false)}>
          <Icon data={close} size={28} />
        </CloseButton>
      </Header>
      <SidebarFilters clearSearch={clearSearch} isInAdmin={isInAdmin} />
    </Container>
  )
}

export default FolderNavigation
