import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { List } from '@equinor/eds-core-react'

const FileSideBar = () => {
  return (
    <Article>
      <Article.Header>File side sheet</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Once you find a file you're interested in, you can click on the corresponding tablerow to open the file sidesheet.
            Here you will be able to download the file and view more information about it.
            The File properties- displays metadata surrounding the file itself, while the Folder properties displays metadata
            about the folder/dbEntry it is stored in. At the very bottom of the sidebar, you will find an overview of the folder
            where it is stored and it’s sibling files.
          </Typography>

        </Article.Section>
        <Article.Section>
          <Article.Gallery>
            <img src="/media/articleImages/fileSideSheet.png" alt="file side sheet" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FileSideBar