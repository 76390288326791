import React from 'react'
import { Breadcrumbs } from '@equinor/eds-core-react'
import styled from 'styled-components'

const { Breadcrumb } = Breadcrumbs

const collapse = (str: string, index: number) => {
  if (str.length > 100) {
    return str.substring(0, 100) + '...'
  }

  return getBreadcrumbName(str, index)
}

const getBreadcrumbName = (breadcrumb: string, index: number) => {
  if (index < 2) {
    return breadcrumb
  }
  try {
    const breadcrumbNoEroomId = breadcrumb.split('_').slice(2).join('_')
    return breadcrumbNoEroomId
  } catch {
    return breadcrumb
  }
}

const StyledCumb = styled(Breadcrumb)`
  white-space: wrap;
 
`

interface Props {
  breadcrumbItems: string[]
  shouldCollapse: boolean
  onBreadcrumbClicked?: (index: number) => void
}

const TableBreadCrumbs = ({ breadcrumbItems, shouldCollapse, onBreadcrumbClicked }: Props) => {
  const crumbs = breadcrumbItems.map((breadcrumb, index) => (
    <StyledCumb key={breadcrumb} onClick={onBreadcrumbClicked ? () => onBreadcrumbClicked(index) : undefined}>
      {shouldCollapse ? collapse(breadcrumb, index) : getBreadcrumbName(breadcrumb, index)}
    </StyledCumb>
  ))

  return (
    <Breadcrumbs
      id="breadcrumbs"
      collapse={shouldCollapse}
      onClick={async e => {
        e.stopPropagation()
      }}
    >
      {crumbs}
    </Breadcrumbs>
  )
}

export default TableBreadCrumbs
