import React from 'react'

interface Props {
	size: number
}

export const PdfIcon = ({ size }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 49 48" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_67_36)">
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2629 18.5498H28.1115V4.3984H11.2828V43.6016H42.2629V18.5498ZM28.1115 1.52988H31.9362H32.0318L45.1309 14.5333L45.1314 14.5339L45.1314 18.5498L45.1314 46.4701H8.41431V1.52988H28.1115ZM30.98 4.61497V15.6813H42.2091L30.98 4.61497Z" fill="#A6A6A6" />
        <rect x="5.73706" y="21.6096" width="42.4542" height="19.506" fill="#DC1D00" />
        <path d="M14.6519 33.0299V36.7201H12.239V26.008H16.0189C18.7181 26.008 20.0677 27.1459 20.0677 29.4218C20.0677 30.4975 19.6793 31.369 18.9024 32.0364C18.1304 32.6987 17.0971 33.0299 15.8023 33.0299H14.6519ZM14.6519 27.8606V31.1997H15.6006C16.8854 31.1997 17.5279 30.6369 17.5279 29.5115C17.5279 28.4109 16.8854 27.8606 15.6006 27.8606H14.6519Z" fill="white" />
        <path d="M22.9751 36.7201V26.008H26.7699C30.5747 26.008 32.4771 27.7485 32.4771 31.2296C32.4771 32.8979 31.9566 34.2301 30.9158 35.2261C29.8799 36.2221 28.498 36.7201 26.7699 36.7201H22.9751ZM25.3879 27.9726V34.7629H26.5831C27.629 34.7629 28.4482 34.4492 29.0408 33.8217C29.6384 33.1942 29.9372 32.3401 29.9372 31.2595C29.9372 30.2385 29.6409 29.4367 29.0483 28.8541C28.4606 28.2664 27.6339 27.9726 26.5682 27.9726H25.3879Z" fill="white" />
        <path d="M41.7863 27.9726H38.0064V30.6021H41.48V32.5593H38.0064V36.7201H35.5936V26.008H41.7863V27.9726Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_67_36">
          <rect width="48.1912" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}