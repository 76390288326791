import React from 'react'
import { Typography } from '@equinor/eds-core-react'

const StickyFooter = () => {
  return (
    <>
      <div>
        <div
          style={{
            display: 'block',
          }}
        />
        <div
          style={{
            position: 'fixed',
            left: '0',
            bottom: '0',
            width: '100%',
            paddingTop: '1em',
            paddingBottom: '1em',
          }}
        >
          <Typography style={{ textAlign: 'center' }}>Project Information Archive © Equinor ASA</Typography>
        </div>
      </div>
    </>
  )
}

export default StickyFooter
