import React from 'react'
import { Grid } from 'semantic-ui-react'
import { StarProgress } from '@equinor/eds-core-react'

const { Column } = Grid

interface Props {
    style?: React.CSSProperties
}

const CenteredStarProgress = ({ style = {} }: Props) => (
  <>
    <Grid container style={style}>
      <Column textAlign="center">
        <StarProgress />
      </Column>
    </Grid>
  </>
)

export default CenteredStarProgress
