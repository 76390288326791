import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SearchDocument } from '../utils/api/payloads'
import {
  removeFiltersThatAreNoLongerInFilterOptions,
  removeFromFilter,
  addToFilter,
  removeParenthesesPart,
  findDifferingElements,
  getBackEndCategoryName,
  getFileIcon,
} from '../utils/helpers'
import { useUrlSearchState } from '../utils/hooks'
import { ColDef, ColumnApi, FirstDataRenderedEvent, IRowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import styled from 'styled-components'
import DetailsSidebar from './Sidebars/DetailsView/DetailsSideBar'
import BreadcrumbsCell from './AgGrid/TableCells/BreadCrumbsCell'
import DirectoryCell from './AgGrid/TableCells/DirectoryCell'
import { ViewContext } from '../contexts/ViewContext'
import { useFilterOptionsContext } from '../contexts/FilterOptionsContext'
import { useSearchStateContext } from '../contexts/SearchStateContext'
import { DEFAULT_SHEET_WIDTH } from '../utils/constants'

import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import "./AgGridTableTheme.css"
import { SortOrder } from '../utils/SortOrderEnum'

const Wrapper = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    overflow: hidden;
`

const TableAndPagination = styled.div<{ width: number }>`
    ${({ width }) => `width: calc( 100% - ${width}px );`}
    display: grid;  
    grid-template-rows: 1fr auto;
    height: 100%; 
    width: 100%; 
    overflow: hidden;
`

const TableContainer = styled.div`
    overflow-x: auto;
    overflow-y: auto;
    flex: 1;
    margin: 20px 20px 0 20px;
    border: 1px solid #e0e0e0;
`

const GridWrapper = styled.div`
    width: 100%;
    height: 100%;
`

interface Props {
  shouldStageDocs: boolean
  documents: SearchDocument[]
  docsChecked: SearchDocument[]
  setDocsChecked: (docs: SearchDocument[] | ((currentDocs: SearchDocument[]) => SearchDocument[])) => void
  onBreadcrumbClicked?: (index: number, breadcrumbItems: string[]) => void
  children?: React.ReactNode
  gridRef: any
  isInAdmin?: boolean
}

const SearchResultsTable = ({
  shouldStageDocs,
  documents,
  docsChecked,
  setDocsChecked,
  onBreadcrumbClicked,
  gridRef,
  children,
  isInAdmin = false,
}: Props) => {
  const [searchState] = useUrlSearchState()
  const { filters } = searchState
  const { setIsSideSheetOpen, downloadMode, stageMode, setCreatedSort, createdSort } = useContext(ViewContext)
  const { filterOptions, setFilterOptions } = useFilterOptionsContext()
  const { updateSearchState } = useSearchStateContext()

  const [selectedDoc, setSelectedDoc] = useState<SearchDocument | null>(null)
  const [focusedRow, setFocusedRow] = useState<number | null>(null)
  const [gridReady, setGridReady] = useState(false)
  const [sideSheetWidth, setSideSheetWidth] = useState<number>(0)
  const [appliedFilterModel, setAppliedFilterModel] = useState<any>(null)
  const [listenForFilterChange, setListenForFilterChange] = useState(false)
  const [docIdFromStorage, setDocIdFromStorage] = useState(sessionStorage.getItem('docId') || null)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)

  const defaultColDef = useMemo<ColDef>(() => ({
    resizable: true,
    sortable: false,
    editable: false,
    suppressMenu: true,
    menuTabs: ['filterMenuTab'],
  }), [])

  useEffect(() => {
    const focusIsSetInUrl = docIdFromStorage !== null
    if (gridReady && documents.length > 0 && gridRef.current && focusIsSetInUrl) {
      const rowIndexToFocus = selectedRowIndex
      setTimeout(() => {
        gridRef.current.api.ensureIndexVisible(rowIndexToFocus, 'top')
        gridRef.current.api.setFocusedCell(rowIndexToFocus, 'title')
        setFocusedRow(rowIndexToFocus)
      }, 400)
    }
  }, [gridReady, documents, selectedRowIndex])

  useEffect(() => {
    if (gridReady && gridRef.current && gridRef.current.api) {
      setTimeout(() => {
        gridRef.current.api.redrawRows()
      }, 0)
    }
  }, [focusedRow, gridReady, gridRef])

  // Set server side datasource
  useEffect(() => {
    if (gridReady && gridRef.current && gridRef.current.api) {
      const getServerSideDatasource = () => {
        return {
          getRows: (params) => {
            params.success({
              rowData: documents,
              rowCount: documents.length,
            })
          }
        }
      }

      const datasource = getServerSideDatasource()
      gridRef.current.api.setServerSideDatasource(datasource)
    }
  }, [gridReady, documents])

  // Set selected doc when focusedDocumentId changes
  useEffect(() => {
    if (docIdFromStorage) {
      const doc = documents.find(doc => doc.indexKey === docIdFromStorage)

      if (doc) {
        setIsSideSheetOpen(true)
        setSideSheetWidth(DEFAULT_SHEET_WIDTH)
        setSelectedDoc(doc)

        const index = documents.findIndex(doc => doc.indexKey === docIdFromStorage)
        setSelectedRowIndex(index)
      } else {
        sessionStorage.removeItem('docId') // remove stale docId from local storage
      }
    }
  }, [documents])


  // Set filter model when filter options change
  useEffect(() => {
    if (gridReady && gridRef.current && gridRef.current.api) {
      const searchStateFilters = searchState.filters
      const appliedFilters = Object.keys(searchStateFilters).reduce((acc, key) => {
        // remove unsupported filters
        if (!['classification', 'itemType', 'fileExtension'].includes(key)) {
          return acc
        }

        const filter = searchStateFilters[key]
        if (filter.length > 0) {
          const newKey = key === 'fileExtension' || key === 'itemType' ? 'fileType' : key

          const filterValues = filter.map(value =>
            filterOptions[key].find(option => option.value === value).label)


          if (newKey === 'fileType' && acc[newKey]) {
            acc[newKey].values = [...acc[newKey].values, ...filterValues]
          } else {
            acc[newKey] = {
              filterType: 'set',
              values: filterValues,
            }
          }
        }
        return acc
      }, {})

      setAppliedFilterModel(appliedFilters)
      gridRef.current.api.setFilterModel(appliedFilters)

      // Delay the activation of filter listening so it does not trigger on initial load
      setTimeout(() => {
        setListenForFilterChange(true)
      }, 500)
    }
  }, [gridReady])

  // Set the select all checkbox to checked if all documents are checked, and vice versa
  // This does not happen automatically because we are using server side data source
  useEffect(() => {
    if (gridReady && gridRef.current?.api) {
      const checkedDocIndexKeys = new Set(docsChecked.map(doc => doc.indexKey))

      const allDocsChecked = documents.every(doc => checkedDocIndexKeys.has(doc.indexKey))
      const noneChecked = !allDocsChecked && documents.every(doc => !checkedDocIndexKeys.has(doc.indexKey))

      if (allDocsChecked || noneChecked) {
        gridRef.current.api.setServerSideSelectionState({ selectAll: allDocsChecked, toggledNodes: [] })
      }
    }
  }, [docsChecked])


  const handleFilterChange = () => {
    if (!listenForFilterChange) return

    const toggleValueInMultipleSelect = (value: string, filterName: string) => {
      const checkIfValueSelected = (value: string, title: string) => filters[title] !== undefined && filters[title].includes(value)
      const isRemove = checkIfValueSelected(value, filterName)
      const newFilter = removeFiltersThatAreNoLongerInFilterOptions(filters, filterOptions, filterName)
      const updatedFilter = isRemove ? removeFromFilter(newFilter, value, filterName) : addToFilter(newFilter, value, filterName)

      setFilterOptions({ [filterName]: [...filterOptions[filterName]] })
      updateSearchState({ filters: updatedFilter }, isInAdmin)
    }

    const currentFilters = gridRef.current.api.getFilterModel()

    Object.keys(currentFilters).forEach(key => {

      // the filter was applied before and now it is removed
      if (appliedFilterModel[key]) {
        const newFilter = findDifferingElements(appliedFilterModel[key].values, currentFilters[key].values)
        if (newFilter.length > 0) {
          const filter = removeParenthesesPart(newFilter[0])
          const category = getBackEndCategoryName(key, filter)
          toggleValueInMultipleSelect(filter, category)
        }
      }
      // a new filter was applied
      else {
        const filter = removeParenthesesPart(currentFilters[key].values[0])
        const category = getBackEndCategoryName(key, filter)
        toggleValueInMultipleSelect(filter, category)
      }
    })

    // a whole category was removed by removing the last filter
    if (Object.keys(currentFilters).length < Object.keys(appliedFilterModel).length) {
      Object.keys(appliedFilterModel).forEach(key => {
        if (!currentFilters[key]) {
          const filter = removeParenthesesPart(appliedFilterModel[key].values[0])
          const category = getBackEndCategoryName(key, filter)
          toggleValueInMultipleSelect(filter, category)
        }
      })

    }

    // only one filter was applied before and now it is removed
    if (Object.keys(currentFilters).length === 0) {
      const filter = removeParenthesesPart(appliedFilterModel[Object.keys(appliedFilterModel)[0]].values[0])
      const category = getBackEndCategoryName(Object.keys(appliedFilterModel)[0], filter)
      toggleValueInMultipleSelect(filter, category)
    }
  }

  const applyCreatedSort = (createdSort: SortOrder, columnApi: ColumnApi) => {
    if (createdSort === SortOrder.ASC) {
      columnApi.applyColumnState({ state: [{ colId: 'createDate', sort: 'asc' }] })
    } else if (createdSort === SortOrder.DESC) {
      columnApi.applyColumnState({ state: [{ colId: 'createDate', sort: 'desc' }] })
    }
  }

  const selectNodes = (params: any, docsChecked: SearchDocument[], documents: SearchDocument[]) => {
    const nodesToBeSelected: IRowNode[] = []
    const checkedDocumentIndexKeys = new Set(docsChecked.map(doc => doc.indexKey))

    params.api.forEachNode((node: IRowNode) => {
      if (checkedDocumentIndexKeys.has(node.data?.indexKey)) {
        nodesToBeSelected.push(node)
      }
    })

    params.api.setNodesSelected({ nodes: nodesToBeSelected, newValue: true })

    const indexKeysFromDocuments = new Set(documents.map(doc => doc.indexKey))
    const indexKeysFromSelectedNodes = new Set(nodesToBeSelected.map(node => node.data.indexKey))
    const shouldSelectAll = indexKeysFromDocuments.size === indexKeysFromSelectedNodes.size && [...indexKeysFromDocuments].every(key => indexKeysFromSelectedNodes.has(key))

    if (shouldSelectAll) {
      params.api.setServerSideSelectionState({ selectAll: true, toggledNodes: [] })
    }
  }

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<SearchDocument>) => {
      selectNodes(params, docsChecked, documents)

      applyCreatedSort(createdSort, params.columnApi)
    }, [docsChecked, documents, createdSort]
  )

  const onRowSelected = (eventParams: any) => {
    // Event is triggered when a single checkbox is clicked
    // If the event is triggered by clicking select all or through the grid api, do nothing
    if (eventParams.source === "uiSelectAll" || eventParams.source === "api" || eventParams.source === "apiSelectAll") {
      return
    }

    // Updates the list of checked documents by either adding the selected document if it's not already in the list, or removing it if it is.
    // We don't know if the event is a select or deselect, so we check if the document is already in the list of checked documents.
    setDocsChecked((currentCheckedDocuments: SearchDocument[]) => {
      const selectedDocument = documents[eventParams.rowIndex]
      const isSelected = currentCheckedDocuments.some(doc => doc.indexKey === selectedDocument.indexKey)

      if (isSelected) {
        return currentCheckedDocuments.filter(doc => doc.indexKey !== selectedDocument.indexKey)
      } else {
        return [...currentCheckedDocuments, selectedDocument]
      }
    })
  }

  const handleSelectionChange = (event: any) => {
    // Event is triggered when select all checkbox is clicked
    // If the event is triggered by clicking a single checkbox or through the grid api, do nothing
    if (event.source === "checkboxSelected" || event.source === "api" || event.source === "apiSelectAll") {
      return
    }

    // If all documents are already checked, select all checkbox should remove all documents from docsChecked
    // Otherwise, select all checkbox should add all documents to docsChecked
    const allDocumentsChecked = documents.every(doc => docsChecked.some(checkedDoc => checkedDoc.indexKey === doc.indexKey))

    setDocsChecked(prevDocsChecked => {
      if (allDocumentsChecked) {
        // Remove the documents of the current search from docsChecked
        return prevDocsChecked.filter(doc => !documents.some(document => document.indexKey === doc.indexKey))
      } else {
        // Add the documents of the current search to docsChecked, but only those not already in docsChecked
        const newDocs = documents.filter(doc => !prevDocsChecked.some(checkedDoc => checkedDoc.indexKey === doc.indexKey))
        return [...prevDocsChecked, ...newDocs]
      }
    })
  }

  const handleSortChange = (sortChangeEvent: any) => {
    const currentColumnState = sortChangeEvent.columnApi.columnModel.getColumnState()
    const createdDateSortColumn = currentColumnState.find((column: any) => column.colId === 'createDate' && column.sort)

    if (!createdDateSortColumn) {
      setCreatedSort(SortOrder.UNDEFINED)
    } else {
      setCreatedSort(createdDateSortColumn.sort === 'asc' ? SortOrder.ASC : SortOrder.DESC)
    }
  }

  const downloadColumnDef: ColDef = {
    headerName: 'Download',
    field: 'download',
    width: 140,
    headerCheckboxSelection: () => true,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelectionCurrentPageOnly: false,
    checkboxSelection: true,
  }

  const stageColumnDef = {
    headerName: 'Stage',
    field: 'stage',
    width: 75,
    headerCheckboxSelection: () => true,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelectionCurrentPageOnly: false,
    checkboxSelection: true,
  }

  const columnDef = [
    ...((downloadMode && !stageMode) ? [downloadColumnDef] : []),
    ...((shouldStageDocs && !downloadMode && stageMode) ? [stageColumnDef] : []),
    {
      field: "fileType",
      cellRenderer: (params: any) => params ? getFileIcon(documents[params.rowIndex]) : null,
      width: 100,
      minWidth: 140,
      checkboxSelection: isInAdmin,
      headerCheckboxSelection: isInAdmin,
      ...(filterOptions.fileExtension && filterOptions.fileExtension.length > 0 && filterOptions.itemType && filterOptions.itemType.length > 0
        ? {
          suppressMenu: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            //buttons: ['reset', 'apply'],
            defaultToNothingSelected: true,
            suppressSelectAll: true,
            values: filterOptions.fileExtension.map(item => item.label).concat(filterOptions.itemType.map(item => item.label))
          }
        }
        : {})
    },
    {
      field: "filename",
      headerName: "Filename",
      minWidth: 250,
      maxWidth: 400,
      flex: 2,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return params.data.title ? params.data.title : params.data.name
      }
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 250,
      maxWidth: 400,
      flex: 2,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        if (params.data.dbentrytitle) { return params.data.dbentrytitle }
        if (params.data.contenttitle) { return params.data.contenttitle }
        if (params.data.title) { return params.data.title }
        return <></>
      }
    },
    {
      field: "docid",
      headerName: "Document ID",
      wrapText: true,
      width: 120,
      cellRenderer: (params: any) => {
        if (params.data.dbentryuniquedocid) { return params.data.dbentryuniquedocid }
        if (params.data.contentdocumentid) { return params.data.contentdocumentid }
        if (params.data.Uniquedocid) { return params.data.Uniquedocid }
        return <></>
      }
    },
    {
      field: "revno",
      headerName: "Rev. Number",
      width: 120,
      cellRenderer: (params: any) => {
        if (params.data.dbentryrevno) { return params.data.dbentryrevno }
        if (params.data.contentrevno) { return params.data.contentrevno }
        return <></>
      }
    },
    {
      field: "status",
      width: 90,
      cellRenderer: (params: any) => {
        if (params.data.dbentrydocumentstatus) { return params.data.dbentrydocumentstatus }
        if (params.data.contentrevstatus) { return params.data.contentrevstatus }
        return <></>
      }
    },
    {
      field: "classification",
      width: 125,
      ...(filterOptions.classification && filterOptions.classification.length > 0
        ? {
          suppressMenu: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            //buttons: ['reset', 'apply'],
            defaultToNothingSelected: true,
            suppressSelectAll: true,
            values: filterOptions.classification.map(item => item.label)
          }
        }
        : {})
    },
    // not showing path in admin to prevent user from navigating to a document that is not staged
    {
      field: "path",
      minWidth: 400,
      maxWidth: 1000,
      flex: 1,
      cellRenderer: (params) =>
        params ?
          <BreadcrumbsCell
            documents={documents}
            rowIndex={params.rowIndex}
            shouldStageDocs={shouldStageDocs}
            onBreadcrumbClicked={isInAdmin ? undefined : onBreadcrumbClicked}
          /> : null,
      wrapText: true,
      autoHeight: true
    },
    {
      field: "directory",
      cellRenderer: (params) =>
        params ?
          <DirectoryCell
            documents={documents}
            rowIndex={params.rowIndex}
            searchState={searchState}
          /> : null,
      width: 110
    },
    {
      field: "createDate",
      width: 123,
      sortable: true,
      cellRenderer: (params) => {
        return params.value ? new Date(params.value).toLocaleDateString() : '_'
      },
    }
  ]

  const handleRowClicked = (e: any) => {
    setIsSideSheetOpen(true)
    setSelectedDoc(documents[e.rowIndex])
    sessionStorage.setItem('docId', documents[e.rowIndex].indexKey)
  }

  const onCellClicked = (params: any) => {
    setFocusedRow(params.rowIndex)
  }

  const closeSideSheet = () => {
    setIsSideSheetOpen(false)
    setSelectedDoc(null)
    setSideSheetWidth(0)
    setFocusedRow(null)
    sessionStorage.removeItem('docId')
    setDocIdFromStorage(null)
  }

  const getRowNodeId = (data) => {
    return data.data.indexKey
  }

  return (
    <>
      <Wrapper>
        <TableAndPagination width={sideSheetWidth}>
          <TableContainer>
            <GridWrapper className="ag-theme-alpine ag-theme-piasearch">
              <AgGridReact
                onFilterChanged={handleFilterChange}
                onSelectionChanged={handleSelectionChange}
                onSortChanged={handleSortChange}
                onGridReady={() => setGridReady(true)}
                unSortIcon={true}
                rowModelType="serverSide"
                getRowId={getRowNodeId}
                ref={gridRef}
                columnDefs={columnDef}
                defaultColDef={defaultColDef}
                animateRows
                enableCellChangeFlash
                rowSelection="multiple"
                onRowClicked={(e) => { handleRowClicked(e) }}
                suppressMovableColumns
                headerHeight={48}
                rowHeight={55}
                suppressRowClickSelection={true}
                suppressMenuHide={true}
                onRowSelected={onRowSelected}
                onFirstDataRendered={onFirstDataRendered}
                getRowStyle={params => (params.node.rowIndex === focusedRow ? { backgroundColor: '#e5faeb' } : undefined)}
                onCellClicked={onCellClicked}
              />
            </GridWrapper>
          </TableContainer>
          {children}
        </TableAndPagination>
        <DetailsSidebar
          closeSideSheet={closeSideSheet}
          selectedDoc={selectedDoc}
          width={sideSheetWidth}
          setWidth={setSideSheetWidth}
        />
      </Wrapper>
    </>
  )
}

export default SearchResultsTable
