import React, { useContext } from 'react'
import FolderNavigationBody from '../Sidebars/FolderNavigation/FolderNavigationBody'
import NonNavigationFilters from './NonNavigationFilters'
import { useSearchStateContext } from '../../contexts/SearchStateContext'
import { useFilterOptionsContext } from '../../contexts/FilterOptionsContext'
import { ViewContext } from '../../contexts/ViewContext'
import {
  updatedNavigationSearchStatePart,
  addToFilter,
  removeFiltersThatAreNoLongerInFilterOptions,
  removeFromFilter,
} from '../../utils/helpers'
import styled from 'styled-components'

const SideBar = styled.div`
    overflow-x: hidden;
    background-color: white;
    padding: 20px 20px 0 20px;
    border-right: 1px solid #e5e5e5;
`

interface Props {
  isInAdmin?: boolean
  clearSearch: (isInAdmin?: boolean) => void
}

const SidebarFilters = ({ isInAdmin, clearSearch }: Props) => {
  const { searchState, updateSearchState } = useSearchStateContext()
  const { filterOptions, setFilterOptions } = useFilterOptionsContext()
  const { sideBarMode } = useContext(ViewContext)
  const { filters } = searchState

  const checkIfValueSelected = (value: string, title: string) => filters[title] !== undefined && filters[title].includes(value)
  const getValueFromLabel = (name: string, lablel: string) => filterOptions[name].find(option => option.label === lablel).value

  const toggleValueInMultipleSelect = (value: string, filterName: string) => {
    const isRemove = checkIfValueSelected(value, filterName)
    const newFilter = removeFiltersThatAreNoLongerInFilterOptions(filters, filterOptions, filterName)
    const updatedFilter = isRemove ? removeFromFilter(newFilter, value, filterName) : addToFilter(newFilter, value, filterName)
    setFilterOptions({ [filterName]: [...filterOptions[filterName]] })
    updateSearchState({ filters: updatedFilter }, isInAdmin)
  }


  const onSelectSingleValue = (name: string, selected: any) => {
    if (typeof selected === 'string' && filters[name]) return

    const value = typeof selected === 'string' ? getValueFromLabel(name, selected) : selected
    const isArray = name === 'project' || name === 'eRoom'
    const finalValue = isArray ? [value] : value

    setFilterOptions({})
    updateSearchState(updatedNavigationSearchStatePart(searchState, name, { [name]: finalValue }), isInAdmin)
  }

  return (
    <SideBar>
      <div style={{ display: sideBarMode === 'navigate' ? 'block' : 'none' }}>
        <FolderNavigationBody
          onSelect={onSelectSingleValue}
          selectedProject={filters.project ? filters.project[0] : undefined}
          isInAdmin={isInAdmin}
          clearSearch={clearSearch}
        />
      </div>
      <div style={{ display: sideBarMode === 'filter' ? 'block' : 'none' }}>
        <NonNavigationFilters onSelect={toggleValueInMultipleSelect} checkIfValueSelected={checkIfValueSelected} />
      </div>
    </SideBar>
  )
}

export default SidebarFilters
