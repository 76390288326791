import React from 'react'

import { DateRange } from '../../utils/api/filter'

import { DateRange as DateRangeComponent } from 'react-date-range'

export const firstDate = new Date(2000, 0, 1)
export const lastDate = new Date()

export const defaultDateRange: DateRange = {
  startDate: firstDate,
  endDate: lastDate,
}

interface DateRangePickerProps {
    value: DateRange
    onChange: (dateRange: DateRange) => void
}

const DateRangePicker = ({ value, onChange }: DateRangePickerProps) => {
  const onCreateDateChange = (dateRange: DateRange) => {
    const newDateRange: DateRange = {
      startDate: removeTimezoneOffset(dateRange.startDate),
      endDate: removeTimezoneOffset(dateRange.endDate),
    }
    onChange(newDateRange)
  }

  return (
    <>
      <DateRangeComponent
        ranges={[value]}
        onChange={(dateRangeObject: { range1: DateRange }) => onCreateDateChange(dateRangeObject.range1)}
        maxDate={lastDate}
        minDate={firstDate}
      />
    </>
  )
}

const removeTimezoneOffset = (date: Date) => {
  const userTimezoneOffsetInMillis = date.getTimezoneOffset() * 60 * 1000
  return new Date(date.getTime() - userTimezoneOffsetInMillis)
}

export default DateRangePicker
