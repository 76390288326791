import React, { useState } from 'react'
import { NavBar } from './components/NavBar'
import { BrowserRouter as Router } from 'react-router-dom'
import Routing from './Routing'
import ErrorDisplay from './components/Error/ErrorDisplay'
import ErrorContext, { ErrorObject } from './components/Error/ErrorContext'

interface ContentProps {
    logOut: Function
}

const Content = ({ logOut }: ContentProps) => {
  const [error, setError] = useState<ErrorObject>(undefined)
  const [isInAdmin, setIsInAdmin] = useState<boolean>(undefined)

  return (
    <ErrorContext.Provider value={{ error: error, setError: setError }}>
      <Router>
        <NavBar logOut={logOut} setIsInAdmin={setIsInAdmin} isInAdmin={isInAdmin} />
        {error !== undefined && <ErrorDisplay error={error} />}
        <Routing isInAdmin={isInAdmin} />
      </Router>
    </ErrorContext.Provider>
  )
}

export default Content
