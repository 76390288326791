import React from 'react'
import { Icon } from '@equinor/eds-core-react'
import { SearchDocument } from '../../../utils/api/payloads'
import { getFolderParts, getDirectoryUrl } from '../../../utils/helpers'

interface DirectoryCellProps {
  documents: SearchDocument[];
  rowIndex: number;
  searchState: any;
}

const DirectoryCell: React.FC<DirectoryCellProps> = ({
  documents,
  rowIndex,
  searchState,
}) => {
  const breadcrumbItems = getFolderParts(documents[rowIndex])
  const directoryUrl = getDirectoryUrl(breadcrumbItems, searchState)

  return (
    <a href={directoryUrl} target="_blank" rel="noreferrer">
      <Icon name="folder" title="Open directory" size={24} color="rgba(0,112,121,255)" />
    </a>
  )
}

export default DirectoryCell
