import React, { useState } from 'react'
import { SearchDocument } from '../../../utils/api/payloads'
import { useCurrentUser, User } from '../../../utils/auth/UserContext'
import SearchResultsTable from '../../../components/SearchResultsTable'
import { getFilterFromDocPath, updatedNavigationSearchStatePart } from '../../../utils/helpers'
import { NAVIGATION_FILTER_NAMES_IN_ORDER } from '../../../utils/constants'
import { useSearchStateContext } from '../../../contexts/SearchStateContext'
import { useFilterOptionsContext } from '../../../contexts/FilterOptionsContext'
import FolderButton from '../../../components/Sidebars/Buttons/FolderButton'
import styled from 'styled-components'
import CommunicationBar from '../../../components/CommunicationCards/CommunicationBar'
import TableHeader from '../../../components/TableHeader'
import GdprButton from '../../../components/inputs/Buttons/GdprButton'
import ExportToExcelButton from '../../../components/inputs/Buttons/ExportToExcelButton'
import FilesToDownloadModal from '../../../components/Modal/FilesToDownloadModal'
import DownloadButton from '../../../components/inputs/Buttons/DownloadButton'

export const VerticalToolbar = styled.div`
    position: absolute;
    z-index: 10;
    top: 62px;
    left: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e0e0e0;
    background-color: white;
    height: calc(100vh - 62px);

`

const Wrapper = styled.div`
    padding-left: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
`

const HorizontalToolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-end;
    border-bottom: 1px solid #e0e0e0;
    background-color: white;
    flex-wrap: wrap;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    margin: 5px 20px 5px 20px;

    p {
      white-space: nowrap;
      padding-right: 10px;
    }

    button {      
      height: 32px;
      border-radius: 7px;
      white-space: nowrap;
    }
`

interface Props {
  documents: SearchDocument[]
  redoSearch: () => void
  isInAdmin?: boolean
  children?: React.ReactNode
}

const SearchResultsView = ({ documents, redoSearch, isInAdmin, children }: Props) => {
  const currentUser: User = useCurrentUser()
  const { searchState, updateSearchState } = useSearchStateContext()
  const { setFilterOptions } = useFilterOptionsContext()

  const [docsChecked, setDocsChecked] = useState<SearchDocument[]>(() => {
    return sessionStorage.getItem('docsChecked') ? JSON.parse(sessionStorage.getItem('docsChecked')) : []
  })
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const gridRef = React.useRef<any>(null)


  //whenever docsChecked changes, save them to local storage
  React.useEffect(() => {
    if (docsChecked.length === 0)
      sessionStorage.removeItem('docsChecked')
    else
      sessionStorage.setItem('docsChecked', JSON.stringify(docsChecked))

  }, [docsChecked])

  const uncheckAllRows = () => {
    setDocsChecked([])
  }

  const uncheckRow = (doc: SearchDocument) => {
    if (gridRef.current) {
      const newDocsChecked = docsChecked.filter(d => d.indexKey !== doc.indexKey)
      setDocsChecked(newDocsChecked)
    }
  }
  const onBreadcrumbClicked = (index: number, breadcrumbItems: string[]) => {
    const filterName = NAVIGATION_FILTER_NAMES_IN_ORDER[index]
    const breadcrumbItemsUpToAndIncludingThisIndex = breadcrumbItems.slice(0, index + 1)
    const filterForSearch = getFilterFromDocPath(breadcrumbItemsUpToAndIncludingThisIndex)

    setFilterOptions({})
    updateSearchState(updatedNavigationSearchStatePart(searchState, filterName, filterForSearch), isInAdmin)
  }

  return (
    <Wrapper className="searchResultsView">
      <FilesToDownloadModal
        downloadModalOpen={downloadModalOpen}
        setDownloadModalOpen={setDownloadModalOpen}
        docsChecked={docsChecked}
        uncheckAllRows={uncheckAllRows}
        uncheckRow={uncheckRow}
      />
      <div className="topBar">
        <VerticalToolbar id="verticalBar">
          <FolderButton />
          {/* <FilterButton /> functionality moved to aggrid header */}
        </VerticalToolbar>
        <CommunicationBar />
        <HorizontalToolbar id="horizontalToolbar">
          <TableHeader isInAdmin={isInAdmin} searchState={searchState} />
          <ButtonContainer>
            <DownloadButton
              uncheckAllRows={uncheckAllRows}
              docsChecked={docsChecked}
              setDownloadModalOpen={setDownloadModalOpen}
            />
            {currentUser.isStageRole && (
              <GdprButton
                uncheckAllRows={uncheckAllRows}
                docsChecked={docsChecked}
                redoSearch={redoSearch}
              />
            )}
            <ExportToExcelButton
              gridRef={gridRef}
            />
          </ButtonContainer>
        </HorizontalToolbar>
      </div>
      <SearchResultsTable
        shouldStageDocs={true}
        documents={documents}
        docsChecked={docsChecked}
        setDocsChecked={setDocsChecked}
        onBreadcrumbClicked={onBreadcrumbClicked}
        gridRef={gridRef}
      >
        {children}
      </SearchResultsTable>
    </Wrapper>
  )
}
export default SearchResultsView
