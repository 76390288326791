import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const ExportControlDisclaimer = () => {
  return (
    <Article>
      <Article.Header>Export control disclaimer</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            COMPLIANCE NOTICE SANCTIONS AND EXPORT CONTROLS RESTRICT THE USE OF CERTAIN INFORMATION
          </Typography>
          <Typography>
            Certain data and/or information in this database is restricted by US, EU, and UK sanctions
            and export controls for export to Russia. This database should not be accessed by anyone in
            Russia or supporting projects in Russia, regardless of their location, without prior clearance
            from
            <a href="https://insight.equinor.com/sites/our-identity/business-ethics-and-compliance/SitePage/57195/export-control-compliance"> LEG BEC</a>.
          </Typography>
          <Typography>
            Export controls, including those of the US, EU, Norway, and UK, restrict the export of certain
            controlled items to end-users in various geographic locations based on foreign policy reasons
            or national security concerns. This includes technology related to controlled items, including
            where accessed electronically or visually. If an end-user will be accessing information such as
            blueprints, models, or operating manuals relating to controlled items, LEG BEC should be consulted
            prior to access to determine if an export license is required. A sample list of commonly controlled
            items can be found
            <a href="https://statoilsrm.sharepoint.com/:w:/r/sites/ExportControlToolbox/Shared%20Documents/General/Export%20Controlled%20Items%20Example%20List%202022.docx?d=w418a07cee5a54a0fbd48d61c630f6292&csf=1&web=1&e=EEa713"> here</a>.
            If a user is unsure whether they are accessing information related to a
            controlled item, please contact LEG BEC.
          </Typography>
          <Typography>
            US sanctions also prohibit the use of US information and technology in countries where comprehensive
            sanctions have been imposed. As of 10 March 2022, these countries include: Crimea Region of Ukraine,
            Cuba, Iran, North Korea, Syria, as well as the Donetsk and Luhansk Peoples Republics.
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default ExportControlDisclaimer