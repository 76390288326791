import React from 'react'
import { Icon } from '@equinor/eds-core-react'
import { NAVIGATION_FILTER_NAMES_IN_ORDER } from '../../../utils/constants'
import { updatedNavigationSearchStatePart } from '../../../utils/helpers'
import { useSearchStateContext } from '../../../contexts/SearchStateContext'
import { useFilterOptionsContext } from '../../../contexts/FilterOptionsContext'
import { clear } from '@equinor/eds-icons'
import FolderHierarchy from './FolderHierarchy'
import ProjectSelection from './ProjectSelection'
import Header from './Header'

Icon.add({ clear })

interface Props {
  onSelect: (key: string, value: string) => void
  isInAdmin: boolean
  clearSearch: (isInAdmin?: boolean, keepSearchString?: boolean) => void
  selectedProject: string
}

const FolderNavigation = ({ onSelect, isInAdmin, clearSearch, selectedProject }: Props) => {
  const { searchState, updateSearchState } = useSearchStateContext()
  const { filterOptions, setFilterOptions } = useFilterOptionsContext()
  const { filters } = searchState

  const fetchedNavigationFilters = NAVIGATION_FILTER_NAMES_IN_ORDER.filter(
    name => (filterOptions[name] !== undefined && filterOptions[name].length > 0) || filters[name] !== undefined
  )

  const removeNavigationFilter = (filterName: string, index: number) => {
    const isFilterProject = filterName === 'project'
    const filterToUpdate = NAVIGATION_FILTER_NAMES_IN_ORDER[index]
    const updatedSearchState = updatedNavigationSearchStatePart(searchState, filterToUpdate, {})

    setFilterOptions({})

    if (isFilterProject) {
      updateSearchState(updatedNavigationSearchStatePart(searchState, filterName, {}), isInAdmin)
      clearSearch(isInAdmin, true)
    } else {
      updateSearchState(updatedSearchState, isInAdmin)
    }
  }

  return (
    <>
      <Header currentLevel={'dropdown'} />
      {
        <ProjectSelection
          fetchedNavigationFilters={fetchedNavigationFilters}
          filterOptions={filterOptions}
          filters={filters}
          onSelect={onSelect}
          removeNavigationFilter={removeNavigationFilter}
          selectedProject={selectedProject}
        />
      }
      <FolderHierarchy
        fetchedNavigationFilters={fetchedNavigationFilters}
        isInAdmin={isInAdmin}
      />
    </>
  )
}

export default FolderNavigation
