import { createContext, useContext } from 'react'
import { SearchState } from '../utils/api/searchState'

type SearchStateContextType = {
    searchState?: SearchState
    updateSearchState?: (statePart: Partial<SearchState>, isInAdmin?: boolean) => void
}

const SearchStateContext = createContext<SearchStateContextType>({})

export const useSearchStateContext = (): SearchStateContextType => useContext(SearchStateContext)

export default SearchStateContext
