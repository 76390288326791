import React from 'react'
import { Breadcrumbs } from '@equinor/eds-core-react'
import { tokens } from '@equinor/eds-tokens'

interface CrumbElementProps {
  crumbText: string
  disabled: boolean
  onClick: (e) => void
}

const CrumbElement = ({ crumbText, disabled, onClick }: CrumbElementProps) => (
  <Breadcrumbs.Breadcrumb
    onClick={onClick}
    style={{
      color: disabled ? tokens.colors.interactive.disabled__text.rgba : tokens.colors.interactive.primary__resting.rgba,
      pointerEvents: disabled ? 'none' : 'auto',
    }}
  >
    <p>{crumbText}</p>
  </Breadcrumbs.Breadcrumb>
)

export default CrumbElement
