import React from 'react'
import MultipleSelectFilter from './MultipleSelectFilter'
import { isNavigationFilter } from '../../utils/helpers'
import { useFilterOptionsContext } from '../../contexts/FilterOptionsContext'

interface Props {
    checkIfValueSelected: (value: string, title: string) => boolean
    onSelect: (value: string, title: string) => void
}

const NonNavigationFilters = ({ checkIfValueSelected, onSelect }: Props) => {
  const { filterOptions } = useFilterOptionsContext()

  const nonNavigationFilters = Object.keys(filterOptions).filter(key => !isNavigationFilter(key))
  const filtersWithOptions = nonNavigationFilters.filter(name => filterOptions[name] !== undefined && filterOptions[name].length > 0)

  return filtersWithOptions.length > 0 ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {filtersWithOptions.map((name, index) => (
        <MultipleSelectFilter
          key={index}
          title={name}
          filterOptions={filterOptions[name]}
          checkIfChecked={(value: string) => checkIfValueSelected(value, name)}
          onCheck={(value: string) => onSelect(value, name)}
        />
      ))}
    </div>
  ) : (
    <p>no filter options available</p>
  )
}

export default NonNavigationFilters
