import React, { useContext, useState } from 'react'
import { SearchDocument } from '../../../utils/api/payloads'
import {
  getFolderParts,
  removeEroomFromName,
  removeFiltersFromSearchParams
} from '../../../utils/helpers'
import { useUrlSearchState } from '../../../utils/hooks'
import FolderElement from '../FolderNavigation/FolderElement'
import styled from 'styled-components'
import { getDirectoryUrl } from '../../../utils/helpers'
import FileElement from './FileElement'
import { Breadcrumbs, CircularProgress, Button, Icon } from '@equinor/eds-core-react'
import { download } from '@equinor/eds-icons'
import { tokens } from '@equinor/eds-tokens'
import { useAppContext } from '../../../utils/api/AppContext'
import { ViewContext } from '../../../contexts/ViewContext'

const Container = styled.div`
    padding: 6px;
`
const FolderSelection = styled.div`
  padding-left: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
`

/*
const FileNavigation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: no-wrap;
`
*/
const Crumbs = styled(Breadcrumbs)`
    padding: 10px;
    margin-top: 10px;
`


const FolderLink = styled.a`
    text-decoration: none;
    color: ${tokens.colors.interactive.primary__resting.rgba};
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    &:hover {
        color: ${tokens.colors.interactive.secondary__link_hover.rgba};
    }
`


interface Props {
  document: SearchDocument
  folders: string[]
  index: number
  // setIndex: (index: number) => void
  documents: SearchDocument[]
  getFilesInFolder: () => void
}

const SisterFileView = ({ document, folders, index, /* setIndex, */ documents, getFilesInFolder }: Props) => {
  const { errors, setErrors } = useContext(ViewContext)
  const [searchState] = useUrlSearchState()
  const { apiClient } = useAppContext()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const downloadFolder = async () => {
    const indexKeys = documents.map(doc => doc.indexKey)
    const postBody = {
      SearchIndexElementKeys: indexKeys,
      ZipFileName: removeEroomFromName(folders[index])
    }
    setIsDownloading(true)
    try {
      await apiClient.downloadMultiple(postBody)
    }
    catch (e) {
      const newError = {
        id: 'downloadError',
        title: 'Error when downloading',
        body: 'Please apply for access to this project',
        variant: 'danger',
      }
      console.error(e)
      setErrors({ ...errors, [newError.id]: newError })
    }
    setIsDownloading(false)
  }

  if (folders[index] === undefined) return null

  return (
    <Container>
      <Crumbs>
        {folders.map((folder, currentIndex) => (
          <Breadcrumbs.Breadcrumb maxWidth={50} key={index} /* onClick={() => setIndex(currentIndex)}*/>
            <i style={{ fontWeight: currentIndex === index ? 'bold' : 'normal' }}>{removeEroomFromName(folder)}</i>
          </Breadcrumbs.Breadcrumb>
        ))}
      </Crumbs>
      <FolderSelection>
        <FolderLink href={getDirectoryUrl(getFolderParts(document), removeFiltersFromSearchParams(searchState))} target="_blank" rel="noreferrer">
          <FolderElement
            external={true}
            name={removeEroomFromName(folders[index])}
            onClick={() => {
              getFilesInFolder()
            }}
          />
        </FolderLink>
        <Button
          disabled={isDownloading}
          style={{ width: '52px' }}
          variant="ghost_icon"
          onClick={downloadFolder}
        >
          <Icon data={download} size={20} />
        </Button>

        {/*
        TODO: Arrows dont work correctly either, for the same reason as the breadcrumbs
        <FileNavigation>
          <Button
            disabled={index === 0}
            style={{ width: '42px' }}
            variant="ghost_icon"
            onClick={() => {
              if (index > 0) {
                setIndex(index - 1)
              }
            }}
          >
            <Icon data={arrow_up} size={16} />
          </Button>
          <Button
            disabled={index === folders.length - 1}
            style={{ width: '42px' }}
            variant="ghost_icon"
            onClick={() => {
              if (index < folders.length - 1) {
                setIndex(index + 1)
              }
            }}
          >
            <Icon data={arrow_down} size={16} />
          </Button>
        </FileNavigation>
        */}
      </FolderSelection>

      {documents.map(doc => (
        <FileElement
          key={doc.indexKey}
          doc={doc}
          highlighted={doc.title === document.title && true}
          document={doc}
          filePosition={documents.indexOf(doc) + 1}
          isDownloading={isDownloading}
          setIsDownloading={setIsDownloading}
        />
      ))}

      {documents.length === 0 && (
        <Loading>
          <CircularProgress variant="indeterminate" size={48} />
        </Loading>
      )}
    </Container>
  )
}

export default SisterFileView
