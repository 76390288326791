import { FacetRequest, Filter } from './filter'
import { defaultDateRange } from '../../components/inputs/DateRangePicker'
import { FACET_ITEMS_TO_ALWAYS_QUERY_FOR } from '../constants'
import { decodeBase64ToObject, encodeObjectToBase64 } from '../helpers'
export interface SearchState {
    currentPage: number
    searchString: string
    filters: Filter
    facets: FacetRequest
    focusedDocumentId?: string
}

const decode = (searchStateAsText: string): SearchState => {
  const searchState: SearchState = decodeBase64ToObject(searchStateAsText)

  if (searchState?.filters?.createDate !== undefined) {
    searchState.filters.createDate.startDate = new Date(searchState.filters.createDate.startDate)
    searchState.filters.createDate.endDate = new Date(searchState.filters.createDate.endDate)
  }
  return searchState
}

export const searchStateToString = (searchState: SearchState): string => {
  return encodeObjectToBase64(searchState)
}

export const searchStateFromString = (searchStateAsText: string): SearchState => {
  if (searchStateAsText === undefined || searchStateAsText === '')
    return {
      searchString: '',
      currentPage: 0,
      filters: {
        createDate: defaultDateRange,
      },
      facets: {
        ...FACET_ITEMS_TO_ALWAYS_QUERY_FOR,
        project: 0,
      },
    }

  let searchStateAsTextClean = searchStateAsText
  if (searchStateAsTextClean.charAt(0) === '?') searchStateAsTextClean = searchStateAsTextClean.substring(1)

  let searchState = decode(searchStateAsTextClean)
  if (searchState.filters === undefined || searchState.filters.createDate === undefined) {
    searchState = {
      ...searchState,
      filters: {
        ...searchState.filters,
        createDate: defaultDateRange,
      },
    }
  }
  if (searchState.searchString === undefined) searchState.searchString = ''
  if (searchState.currentPage === undefined) searchState.currentPage = 0
  return searchState
}
