import React, {useContext} from 'react'
import styled from 'styled-components'
import { Button , Icon} from '@equinor/eds-core-react'
import { SearchDocument } from '../../../utils/api/payloads'
import { useAppContext } from '../../../utils/api/AppContext'
import { delay } from '../../../utils/helpers'
import { ViewContext } from '../../../contexts/ViewContext'
import { search_in_page } from '@equinor/eds-icons'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  p {
    white-space: nowrap;
    padding-right: 10px;
  }
`

interface Props {
    docsChecked: SearchDocument[]
    uncheckAllRows: () => void
    redoSearch: () => void
}

const GdprButton = ({ docsChecked, redoSearch, uncheckAllRows }: Props) => {
  const { apiClient } = useAppContext()
  const { stageMode, setStageMode, downloadMode } = useContext(ViewContext)

  const stageDocumentsForDelete = async (checkedDocs: SearchDocument[]) => {
    await apiClient.updateStageStatusMultiple(
      checkedDocs.map(doc => doc.indexKey),
      true
    )
    // Wait for the Azure search index to update before search
    await delay(500)
    redoSearch()
  }

  const cancelStaging = () => {
    uncheckAllRows()
    setStageMode(false)
  }

  return (

    <Wrapper>
      {
        stageMode ?
          <>
            <Button variant="outlined" onClick={() => cancelStaging()}>
                Cancel
            </Button>
            <Button disabled={docsChecked.length === 0} onClick={() => stageDocumentsForDelete(docsChecked)}>
              {`Stage ${docsChecked.length} documents for GDPR evaluation`}
            </Button>
          </>
          :
          <Button variant="ghost" disabled={downloadMode} onClick={() => setStageMode(true)}>
            <Icon data={search_in_page} size={20} />
                Report GDPR violations
          </Button>
      }

    </Wrapper>


  )
}

export default GdprButton
