import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { Table } from '@equinor/eds-core-react'
import { ICON_MAPPING } from '../../../utils/constants'

const EroomJsonFiles = () => {
  return (
    <Article>
      <Article.Header>eRoom JSON Files</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            JSON is a file format, just like word, pdf or excel. In PIA you can find JSON files that were stored as such
            in eRoom, but you may also find JSON files that were created to store eRooms metadata. You can see an overview
            of this content in the table below.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                  Icon
                </Table.Cell>
                <Table.Cell>
                  ItemType
                </Table.Cell>
                <Table.Cell>
                  Description
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {ICON_MAPPING.INFO}
                </Table.Cell>
                <Table.Cell>
                  db, dbEntry, and discussion
                </Table.Cell>
                <Table.Cell>
                  A folder in eRoom. This filetype shows the metadata of the folder
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {ICON_MAPPING.MESSAGE}
                </Table.Cell>
                <Table.Cell>
                  topic
                </Table.Cell>
                <Table.Cell>
                  A collection of comments. These were previously stored in "discussion" folders
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {ICON_MAPPING.LINK}
                </Table.Cell>
                <Table.Cell>
                  link
                </Table.Cell>
                <Table.Cell>
                  A link to a file or folder in eRoom
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {ICON_MAPPING.NOTE}
                </Table.Cell>
                <Table.Cell>
                  note
                </Table.Cell>
                <Table.Cell>
                  A note created in eRoom
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {ICON_MAPPING.EMAIL}
                </Table.Cell>
                <Table.Cell>
                  mailPage
                </Table.Cell>
                <Table.Cell>
                  A mail page created in eRoom
                </Table.Cell>
              </Table.Row>

            </Table.Body>
          </Table>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>How to open JSON files</Article.SubHeader>
          <Typography>
            If you are using a windows operating system, we recommend using the windows native application “Notepad”
            to open JSON files. If you are using a MAC, we recommned “Notes”. Right click the file and select “Open with” -`&gt;` “Notepad”.
          </Typography>
          <Article.Gallery>
            <img src="/media/articleImages/openJsonFiles.png" alt="how to open json files" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default EroomJsonFiles