import React from 'react'
import Article from '../../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { List } from '@equinor/eds-core-react'
interface props {
    title: string
    updateList: string[]
}

const UpdatesArticle = ({ title, updateList }: props) => {
  return (
    <Article>
      <Article.Header>{title}</Article.Header>
      <Article.Body>
        <List>
          {updateList.map((update, index) => (
            <List.Item key={index}>
              <Typography>{update}</Typography>
            </List.Item>
          ))}
        </List>
      </Article.Body>
    </Article>
  )
}

export default UpdatesArticle