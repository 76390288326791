import React from 'react'

interface Props {
	size: number
}

export const PowerPointIcon = ({ size }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M43.6188 18.5498H29.5239V4.39841H12.7617V43.6016H43.6188V18.5498ZM29.5239 1.52988H33.3331H33.4287L46.4763 14.5339V18.5498H46.476V46.4701H9.90454V1.52988H29.5239ZM32.3811 4.61533V15.6813H43.5652L32.3811 4.61533Z" fill="#A6A6A6" />
      <path d="M1.52417 12.6215L21.1432 9.94422V40.8287L1.52417 37.8645V12.6215Z" fill="#E23926" />
      <path d="M10.0814 27.4753V32.3187H6.91455V18.259H11.8756C15.4183 18.259 17.1897 19.7526 17.1897 22.7397C17.1897 24.1516 16.6799 25.2954 15.6602 26.1713C14.6471 27.0406 13.2908 27.4753 11.5913 27.4753H10.0814ZM10.0814 20.6906V25.0732H11.3266C13.013 25.0732 13.8562 24.3346 13.8562 22.8574C13.8562 21.4128 13.013 20.6906 11.3266 20.6906H10.0814Z" fill="white" />
      <path d="M26.7729 21.4183V12.8149C25.8345 12.8358 24.934 13.0103 24.0956 13.3144V29.1398C24.9908 29.4644 25.9568 29.6414 26.9641 29.6414C31.5473 29.6414 35.2747 25.9771 35.3763 21.4183H26.7729Z" fill="#E23926" />
      <path d="M24.0956 32.51H41.1155V35.3785H24.0956V32.51Z" fill="#E23926" />
      <path d="M24.0956 38.247H41.1155V41.1155H24.0956V38.247Z" fill="#E23926" />
    </svg>
  )
}