import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const FolderNavigationInfo = () => {
  return (
    <Article>
      <Article.Header>Folder Navigation</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Use the folder navigator on the lefthand side of the screen to select a project.  When a project is selected,
            you may further navigate to specific sub-folders through the folder hierarchy. In addition to using the folder
            navigator, you can also use the breadcrumb paths underneath the searchbar to navigate the folder hierachy.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.Gallery>
            <img src="/media/articleImages/folderNavigation.png" alt="folder navigation" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FolderNavigationInfo