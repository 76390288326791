import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'
import { List } from '@equinor/eds-core-react'

const FileSideBar = () => {
  return (
    <Article>
      <Article.Header>Download</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            You download files one by one, or you can downlaod multiple files at once by using the “bulk download” tool.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Single Download</Article.SubHeader>
          <Typography>
                To download a single file you can click the file in the search result tabel and press the "download"
                button in the file sidebar. While you're in the sidebar you can view content from the same folder, by
                scrolling down to the folder overview. Here you can download sibling files by pressing the download
                icon next to the filename. You can also download the entire folder as a zip. by pressing the download
                button next to the folder name
          </Typography>
          <Article.Gallery>
            <img src="/media/articleImages/singleFileDownload.png" alt="downloading single files" />
          </Article.Gallery>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Bulk Download</Article.SubHeader>
          <Typography>
            To download multiple files click the "bulk download" button above the search result table and select files
            using the checkboxes. Once you have seleted the files you want, simply press "download" to download a zip of
            these files. You also rename the zip filename if you wish.
          </Typography>
          <Article.Gallery>
            <img src="/media/articleImages/bulkDownloadButton.png" alt="bulk downloading files button" />
          </Article.Gallery>
          <Article.Caption>Initiate the download process by clicking the "bulk Download" button</Article.Caption>
          <Article.Spacer />
          <Article.Gallery>
            <img src="/media/articleImages/bulkDownloadCheck.png" alt="bulk downloading files checkbox" />
          </Article.Gallery>
          <Article.Caption>Use the Checkboxes to select the files for download. The checkboxes will presist as you search and navigate. When ready, click on the "download Files" button</Article.Caption>
          <Article.Spacer />
          <Article.Gallery>
            <img src="/media/articleImages/bulkDownloadModal.png" alt="bulk downloading overview modal box" />
          </Article.Gallery>
          <Article.Caption>Review the final list of the files to be downloaded, give the Zip file a name, and click on the Download button to start downloading.</Article.Caption>
          <Article.Spacer />
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Export to Excel</Article.SubHeader>
          <Typography>
            If you want to see the current search result in excel-format, you may use the “export to Excel” tool. Note
            that this will only export the current page you are on, and not the whole search result.
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FileSideBar