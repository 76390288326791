import React, { useContext } from 'react'
import styled from 'styled-components'
import { ViewContext } from '../../contexts/ViewContext'
import CommunicationCard from './CommunicationCard'

const Container = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 600px;
`

const CommunicationBar = () => {
  const { errors } = useContext(ViewContext)

  return (
    <Container id="communicationBar">
      {Object.keys(errors).map(key => {
        const error = errors[key]
        return (
          <CommunicationCard
            key={key}
            title={error.title}
            body={error.body}
            variant={error.variant}
            id={key}
            timeOut={error.timeOut}
            action={error.action}
          />
        )
      })}
    </Container>
  )
}

export default CommunicationBar
