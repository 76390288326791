import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const WhatIsPia = () => {
  return (
    <Article>
      <Article.Header>What is PIA?</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Project Information Archive (PIA) contains all information and data from eRoom Classic. eRoom was
            the main collaboration solution in projects from 2007 until around 2017 and was used both for
            project internal collaboration and contracts. From 2017 it was gradually phased out and exchanged
            with Pims DC and SharePoint Online. In 2021 eRoom was closed and its entire content were migrated to PIA.
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default WhatIsPia