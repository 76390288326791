import React from 'react'
import { Typography } from '@equinor/eds-core-react'
import { tokens } from '@equinor/eds-tokens'

interface Props {
    message: string
}

export const ServiceMessage = ({message}: Props) => {
  return (
    <div
      style={{
        backgroundColor: tokens.colors.interactive.warning__resting.rgba,
        borderRadius: '10px',
        marginBottom: '30px',
        height: '150px',
        width: '900px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Typography variant="h2">Currently we are experiencing technical problems with downloading files in PIA. </Typography>
      <Typography variant="h2">We are working on resolving these issues at the moment. Please try again later.</Typography>
      <Typography variant="h2">{message}</Typography>
    </div>
  )
}
