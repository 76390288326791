import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { more_vertical } from '@equinor/eds-icons'
import { Icon } from '@equinor/eds-core-react'

const ResizeContainer = styled.div<{ orientation: 'left' | 'right' }>`
    cursor: col-resize;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 100%;

    ${(props: { orientation: string }) => (props.orientation === 'left' ? 'left: -16px;' : 'right: -16px;')}
    border-radius: 25px;

    &:hover {
        background-color: #dedede;
    }

    &:active {
        background-color: #007078;
    }
`

const ResizeIcon = styled(Icon)`
    overflow: visible !important;
    width: 22px;
`

const HideCursor = createGlobalStyle`
  * {
    cursor: none !important;
    user-select: none !important;
  }
`

interface Props {
    orientation: 'left' | 'right'
    minWidth: number
    maxWidth: number
    width: number
    setWidth: (width: number) => void
}

const Resize = ({ orientation, minWidth, maxWidth, width, setWidth }: Props) => {
  const [startX, setStartX] = useState<number | null>(null)
  const animationFrameId = useRef<number | null>(null)
  const [hideCursor, setHideCursor] = useState(false)
  const widthRef = useRef<number>(width)

  useEffect(() => {
    widthRef.current = width
  }, [width])

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (startX === null) return

      if (animationFrameId.current !== null) {
        cancelAnimationFrame(animationFrameId.current)
      }

      animationFrameId.current = requestAnimationFrame(() => {
        const deltaX = event.clientX - startX
        const newWidth = orientation === 'left' ? widthRef.current - deltaX : widthRef.current + deltaX
        const boundedWidth = Math.min(Math.max(newWidth, minWidth), maxWidth)

        setWidth(boundedWidth)
        // Update the widthRef.current after setting the bounded width
        widthRef.current = boundedWidth
        // Update startX after setting the bounded width
        setStartX(event.clientX)
      })
    }

    const handleMouseUp = () => {
      setStartX(null)
      if (animationFrameId.current !== null) {
        cancelAnimationFrame(animationFrameId.current)
      }
      setHideCursor(false)
    }

    if (startX !== null) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('mouseover', handleMouseOver)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mouseover', handleMouseOver)
      if (animationFrameId.current !== null) {
        cancelAnimationFrame(animationFrameId.current)
      }
    }
  }, [startX, minWidth, maxWidth, orientation, setWidth])

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setStartX(event.clientX)
    setHideCursor(true)
  }

  const handleMouseOver = (event: MouseEvent) => {
    if (startX !== null) {
      event.preventDefault()
    }
  }

  return (
    <div className='resize'>
      {hideCursor && <HideCursor />}
      <ResizeContainer onMouseDown={handleMouseDown} orientation={orientation}>
        <ResizeIcon data={more_vertical} color={'#00727a'} />
      </ResizeContainer>
    </div>
  )
}

export default Resize
