import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Typography, Icon, Menu, SideSheet } from '@equinor/eds-core-react'
import { info_circle, account_circle, help_outline, home, menu  } from '@equinor/eds-icons'
import { tokens } from '@equinor/eds-tokens'
import styled from 'styled-components'
import { useCurrentUser, User } from '../utils/auth/UserContext'

const Wrapper = styled.div`
    height: 60px;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${tokens.colors.ui.background__medium.rgba};
    background-color: ${tokens.colors.interactive.secondary__resting.rgba};
    transition: background-color 0.3s ease-in-out;
`

const SiteHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 22px;
    white-space: nowrap;
`

const HeaderText = styled(Typography)`
    color: white;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    font-size: 20px;
`

const UserNameButton = styled.button`
    padding: 0;
    z-index: 2;
    border: none;
    background-color: transparent;

    & > p {
    font-weight: bold;
    }
`

const HomeLink = styled(NavLink)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-decoration: none;
`

const AdminIndicator = styled(Typography)`
    color: white;
    font-weight: bold;
    font-size: 16px;
    opacity: 0.7;
`

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 20px;
    && div {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
    }

    p {
      transition: color 0.3s ease-in-out;
      color: white;
      &:hover, &:hover p, &:hover a {
        cursor: pointer;
    }
  }
`

const Navigation = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  gap: 10px;
`

const NavItem = styled(NavLink)<{ $isActive: boolean }>`
  padding: 0 15px;
  border-bottom: 3px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  p, a {
    font-weight: bold;
    white-space: nowrap;
    transition: color 0.3s ease-in-out;
    color: white;
    &:hover, &:hover p, &:hover a {
      cursor: pointer;
    }
  }

  &.active {
    border-bottom-color:  white ;
  }
`

const MobileNavItem = styled(NavLink)<{ $isActive: boolean }>`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  p, a {
    font-size: 16px;
    white-space: nowrap;
    transition: color 0.3s ease-in-out;
    &:hover, &:hover p, &:hover a {
      cursor: pointer;
    }
  }

  &.active {
    background-color: ${tokens.colors.interactive.primary__hover_alt.rgba};
  }
`

const HamburgerMenu = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`
const HamburgerSideSheet = styled(SideSheet)`
  padding: 0;
  position: fixed;

  & > div:first-child {
    padding: 20px 10px;
  }
`
const HamburgerContent = styled.div`
  display: flex;
  flex-direction: column;  
  justify-content: space-between;
  height: calc(100vh - 90px);
`

const MobileUserInfo = styled.div`
  border-top: 1px solid #e0e0e0;
  padding: 20px 10px;  
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    font-weight: bold;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    color: ${tokens.colors.interactive.primary__resting.rgba};
    transition: color 0.3s ease-in-out;
    padding: 0;

    &:hover {
      cursor: pointer;
      color: ${tokens.colors.interactive.primary__hover.rgba};
    }
  }
`

interface NavBarProps {
    logOut: Function
    setIsInAdmin: (pressedAdmin: boolean) => void
    isInAdmin: boolean
}

export const NavBar = ({ logOut, setIsInAdmin, isInAdmin }: NavBarProps) => {
  const currentUser: User = useCurrentUser()
  const currentPath = useLocation().pathname
  const MOBILE_VIEW_WIDTH = 950

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [buttonEl, setButtonEl] = useState(null)
  const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(false)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= MOBILE_VIEW_WIDTH)

  // set isInAdmin to true if the user is in the admin page and has the delete role
  useEffect(() => {
    if (currentPath.startsWith('/admin') && currentUser.isDeleteRole && !isInAdmin) {
      setIsInAdmin(true)
    }
  }, [currentUser])

  // activate mobile menu based on window size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= MOBILE_VIEW_WIDTH)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const openMenu = e => {
    setMenuOpen(true)
    setButtonEl(e.target)
  }

  const closeMenu = () => {
    setMenuOpen(false)
    setButtonEl(null)
  }

  return (
    <>
      <Wrapper $isInAdmin={isInAdmin}>
        <SiteHeader>
          <HomeLink to='/'>
            <img src="https://cdn.eds.equinor.com/logo/equinor-logo-primary.svg#white" alt="Equinor" style={{ width: 50 }} />
            <HeaderText $isInAdmin={isInAdmin} variant="h1">Project Information Archive</HeaderText>
          </HomeLink>
          <AdminIndicator>
            {
              currentPath.startsWith('/admin') ? '/ Reported Files' :
                currentPath.startsWith('/guide') ? '/ User Guide' :
                  ''
            }
          </AdminIndicator>
        </SiteHeader>
        { !isMobileView ? (
          <Navigation>
            <UserInfo $isInAdmin={isInAdmin}>
              <Icon data={account_circle} size={20} color="white" />
              <div>
                <UserNameButton onClick={e => (menuOpen ? closeMenu() : openMenu(e))}>
                  <Typography variant="body_short">{currentUser.username}</Typography>
                </UserNameButton>
              </div>
              <Menu id="menu-on-button" open={Boolean(buttonEl)} anchorEl={buttonEl} onClose={closeMenu}>
                <Menu.Item onClick={() => {logOut()}}>
                 Log Out
                </Menu.Item>
              </Menu>
            </UserInfo>
            <NavItem
              $isInAdmin={isInAdmin}
              to='/guide'
              className={({ isActive }) => isActive ? "active" : "" }
            >
              <Icon data={help_outline} size={20} color="white" />
              <Typography variant="body_short">User Guide</Typography>
            </NavItem>
            {currentUser.isDeleteRole && (
              <NavItem
                $isInAdmin={isInAdmin}
                to='/admin'
                onClick={() => setIsInAdmin(true)}
                className={({ isActive }) => isActive ? "active" : "" }
              >
                <Icon data={info_circle} size={20} color="white" />
                <Typography variant="body_short">Reported Files</Typography>
              </NavItem>
            )}
            <NavItem
              $isInAdmin={isInAdmin}
              onClick={() => setIsInAdmin(false)}
              to= '/'
              className={({ isActive }) => isActive ? "active" : "" }
            >
              <Icon data={home} size={20} color="white" />
              <Typography variant="body_short">Home</Typography>
            </NavItem>
          </Navigation> ) :

          <HamburgerMenu
            onClick={() => setToggleMobileMenu(true)}
            variant="contained_icon"
            aria-label="Open menu"
            data={menu}
            size={30}
            color="white"
          />

        }
      </Wrapper>
      <>
        <HamburgerSideSheet
          title = "Menu"
          open={toggleMobileMenu}
          onClose={() => setToggleMobileMenu(false)}
          isScrollLocked={false}
          style={{ zIndex: 1000 }}
        >
          <HamburgerContent>
            <div>
              <MobileNavItem
                $isInAdmin={isInAdmin}
                onClick={() => setIsInAdmin(false)}
                to= '/'
                className={({ isActive }) => isActive ? "active" : "" }
              >
                <Icon data={home} size={20} color="black" />
                <Typography variant="body_short">Home</Typography>
              </MobileNavItem>
              {currentUser.isDeleteRole && (
                <MobileNavItem
                  $isInAdmin={isInAdmin}
                  to='/admin'
                  onClick={() => setIsInAdmin(true)}
                  className={({ isActive }) => isActive ? "active" : "" }
                >
                  <Icon data={info_circle} size={20} color="black" />
                  <Typography variant="body_short">Reported Files</Typography>
                </MobileNavItem>
              )}
              <MobileNavItem
                $isInAdmin={isInAdmin}
                to='/guide'
                className={({ isActive }) => isActive ? "active" : "" }
              >
                <Icon data={help_outline} size={20} color="black" />
                <Typography variant="body_short">User Guide</Typography>
              </MobileNavItem>
            </div>
            <MobileUserInfo>
              <Icon data={account_circle} size={35} color="black" />
              <div>
                <Typography variant="body_short">{currentUser.username}</Typography>
                <button onClick={() => {logOut()}}>log out</button>
              </div>
            </MobileUserInfo>
          </HamburgerContent>
        </HamburgerSideSheet>
      </>
    </>
  )
}

export default NavBar