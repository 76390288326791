import React from 'react'
import Article from '../Components/Article'
import { Typography, Button } from '@equinor/eds-core-react'

const WhoToContact = () => {
  return (
    <Article>
      <Article.Header>Who can I contact?</Article.Header>
      <Article.Body>
        <Article.Section>
          <Article.SubHeader>Support</Article.SubHeader>
          <Typography>
            If you run into technical problems you can contact support in Service Now.
          </Typography>
          <Article.Spacer />
          <Button
            onClick={() => window.open('https://equinor.service-now.com/selfservice?id=sc_cat_item_guide&sys_id=5067d43ddb44e7408a0f9407db9619ab', '_blank')}
          >Create Support Ticket in Service Now</Button>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Project owner</Article.SubHeader>
          <Typography>
            If you have questions or improvemt suggestions regarding PIA , you can contact the current project owner: Kjetil Strømsholm
          </Typography>
          <Article.Spacer />
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default WhoToContact