import React, { useContext, useEffect } from 'react'
import { Autocomplete, Icon } from '@equinor/eds-core-react'
import { AutocompleteChanges } from '@equinor/eds-core-react/dist/types/components/Autocomplete'
import styled from 'styled-components'
import { clear } from '@equinor/eds-icons'
import { ViewContext } from '../../../contexts/ViewContext'

Icon.add({ clear })

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`

const Select = styled(Autocomplete)`
    margin-bottom: 10px;
    flex-grow: 4;
    position: relative;
    top: 6px;
`

const ClearIcon = styled(Icon)`
    margin: 15px 5px 15px 15px;
    cursor: pointer;
`

interface ProjectSelectionProps {
  fetchedNavigationFilters: string[]
  filterOptions: any
  filters: any
  onSelect: (key: string, value: string) => void
  removeNavigationFilter: (filterName: string, index: number) => void
  selectedProject: string
}

const ProjectSelection: React.FC<ProjectSelectionProps> = ({
  fetchedNavigationFilters,
  filterOptions,
  filters,
  onSelect,
  removeNavigationFilter,
  selectedProject,
}) => {
  const { setFilePath, setCurrentLevel } = useContext(ViewContext)
  const valueInFilter = (name: string) => filters[name]


  useEffect(() => {
    const newFilePath = []
    if (fetchedNavigationFilters) {
      fetchedNavigationFilters.forEach(name => {
        newFilePath.push(filterOptionsInDropdown(filterOptions[name], name))
      })
      setFilePath(newFilePath)
    }
  }, [filterOptions])

  const filterOptionsInDropdown = (filteroptionsForName: { value: string; label: string }[], name: string) => {
    if (filteroptionsForName === undefined) {
      return []
    }
    if (name === 'project') {
      return filteroptionsForName
        .map(option => option.label)
        .sort(function (a, b) {
          if (a.toLowerCase() < b.toLowerCase()) return -1
          if (a.toLowerCase() > b.toLowerCase()) return 1
          return 0
        })
    } else {
      return filteroptionsForName.map(option => option.label)
    }
  }

  const removeProjectFilter = projectIndex => {
    removeNavigationFilter('project', projectIndex)
    setFilePath([])
    setCurrentLevel('')
  }

  const selectProject = project => {
    onSelect('project', project)
    setCurrentLevel('project')
  }

  const renderProjectFilter = () => {
    const projectIndex = fetchedNavigationFilters.indexOf('project')

    if (projectIndex !== -1) {
      return (
        <Wrapper key={projectIndex}>
          <Select
            hideClearButton={true}
            key={projectIndex}
            placeholder={'Search for a project'}
            value={valueInFilter('project')}
            options={
              filterOptions['project'] ? filterOptionsInDropdown(filterOptions['project'], 'project') : [filters['project']]
            }
            onOptionsChange={(changes: AutocompleteChanges<string>) => {
              return selectProject(changes.selectedItems[0])
            }}
          />
          {selectedProject && (
            <ClearIcon
              size={20}
              onClick={() => removeProjectFilter(projectIndex)}
              data={clear}
            />
          )}
        </Wrapper>
      )
    } else {
      return null
    }
  }

  return renderProjectFilter()
}
export default ProjectSelection
