import React from 'react'
import { Checkbox, Typography } from '@equinor/eds-core-react'
import styled from 'styled-components'

const SmallerCheckbox = styled(Checkbox)`
    svg {
        width: 20px;
        height: 20px;
    }
`

interface Props {
    title: string
    filterOptions: { label: string; value: string }[]
    checkIfChecked: (value: string) => boolean
    onCheck: (value: string) => void
}

const MultipleSelectFilter = ({ title, filterOptions, checkIfChecked, onCheck }: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '25px' }}>
      <Typography bold>{title}</Typography>
      {filterOptions.map(filterOption => (
        <SmallerCheckbox
          label={filterOption.label}
          key={filterOption.value}
          checked={checkIfChecked(filterOption.value)}
          onChange={() => onCheck(filterOption.value)}
        />
      ))}
    </div>
  )
}

export default MultipleSelectFilter
