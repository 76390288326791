import React from 'react'
import Article from '../Components/Article'
import { Typography, Table,  } from '@equinor/eds-core-react'
import { ICON_MAPPING, EXTENSIONS_MAPPING, ITEM_TYPE_MAPPING } from '../../../utils/constants'

const FolderNavigationInfo = () => {
  const cell = (icon: JSX.Element, fileType: string, extentions: string []) => {
    return (
      <Table.Row>
        <Table.Cell>
          {icon}
        </Table.Cell>
        <Table.Cell>
          {fileType}
        </Table.Cell>
        <Table.Cell>
          {extentions.map(ext => ext).join(', ')}
        </Table.Cell>
      </Table.Row>
    )
  }


  return (
    <Article>
      <Article.Header>Filter and sort</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            Some of the columns in the search result table can be used to filter or sort your search.
            You can filter by “Filtetype” and “classification”. As well as sort by “Created date”.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.Gallery>
            <img src="/media/articleImages/FilterAndSort.png" alt="filtering and sorting search results" />
          </Article.Gallery>
        </Article.Section>

        <Article.Section>
          <Article.SubHeader>Filetype</Article.SubHeader>
          <Typography>
            “Filetype” is a combination of the file properties: “file extension” and “itemType”.
          </Typography>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                    Icon
                </Table.Cell>
                <Table.Cell>
                    Item type
                </Table.Cell>
                <Table.Cell>
                    File extention(s)
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {
                Object.keys(ICON_MAPPING).map((key) => {
                  const fileType = ITEM_TYPE_MAPPING[key] ? ITEM_TYPE_MAPPING[key].toLowerCase() : 'File' // Fallback to 'N/A' if undefined
                  const extensions = EXTENSIONS_MAPPING[key] || ['.JSON'] // Fallback to 'N/A' if undefined
                  return cell(ICON_MAPPING[key], fileType, extensions)
                })
              }
            </Table.Body>
          </Table>
          <Article.Caption>
            Do you want to see a spesific file icon that is not listed here? Contact the PIA Project Owner to make a request.
          </Article.Caption>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Classification</Article.SubHeader>
          <Typography>
            “Internal” files are available for all employess. “Restricted” files requires project access. you can request project access in AccessIT.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Created date</Article.SubHeader>
          <Typography>
            Click the “sort” icon once to sort by oldest created date, and twice to sort by latest. Click the icon again a third time to remove sorting.
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default FolderNavigationInfo