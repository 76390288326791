import React, { useState, useContext, useEffect } from 'react'
import { Button, Icon } from '@equinor/eds-core-react'
import styled from 'styled-components'
import { Typography } from '@equinor/eds-core-react'
import { download } from '@equinor/eds-icons'
import { ViewContext } from '../../../contexts/ViewContext'
import { MAX_DOWNLOAD_SIZE } from '../../../utils/constants'
import { SearchDocument } from '../../../utils/api/payloads'
import { bytesToMegabytes } from '../../../utils/helpers'
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  p {
    white-space: nowrap;
    padding-right: 10px;
  }
`

const Counter = styled(Typography)`
    color: ${(props: { withinLimit: boolean }) => props.withinLimit ? 'green' : 'red'};
    font-weight: bold;
`

interface Props {
  docsChecked: SearchDocument[]
  uncheckAllRows: () => void
  setDownloadModalOpen: (isOpen: boolean) => void
}

const DownloadButton = ({ docsChecked, uncheckAllRows, setDownloadModalOpen }: Props) => {
  const { downloadMode, setDownloadMode, stageMode } = useContext(ViewContext)
  const [megabytesToDownload, setMegabytesToDownload] = useState(0)
  const [filesSelected, setFilesSelected] = useState(0)

  useEffect(() => {

    let totalSize = 0
    if (docsChecked.length > 0) {
      docsChecked.forEach(doc => totalSize += bytesToMegabytes(doc.size))
    }

    setMegabytesToDownload(Math.floor(totalSize * 100) / 100)
    setFilesSelected(docsChecked.length)

  } , [docsChecked])

  const cancelDownload = () => {
    uncheckAllRows()
    setDownloadMode(false)
  }

  const downloadFiles = () => {
    setDownloadModalOpen(true)
  }

  return (
    <Wrapper>
      {downloadMode ? (
        <>
          <Typography>{filesSelected} files selected </Typography>
          <Counter withinLimit={megabytesToDownload <= MAX_DOWNLOAD_SIZE}>
            {megabytesToDownload} / {MAX_DOWNLOAD_SIZE} MB
          </Counter>
          <Button variant="outlined" onClick={cancelDownload}>Cancel</Button>
          <Button disabled={(megabytesToDownload >= MAX_DOWNLOAD_SIZE || filesSelected === 0)} onClick={downloadFiles}>
            <Icon data={download} size={20} />
                        Download Files
          </Button>
        </>
      ) : (
        <Button variant="ghost" disabled={stageMode} onClick={() => setDownloadMode(true)}>
          <Icon data={download} size={20} />
          Bulk download
        </Button>
      )}
    </Wrapper>
  )
}

export default DownloadButton
