import { DependencyList, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SearchState, searchStateFromString } from './api/searchState'

export const useUpdate = (fun: (...rest: any[]) => any, deps?: DependencyList) => {
  // Similar to useEffect but does not render on mount.
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    fun()
  }, deps)
}

export const useUrlSearchState = ():  [SearchState, () => boolean] => {
  const location = useLocation()
  const searchStateAsStringFromUrl = location.search
  const searchStateFromUrl = searchStateFromString(searchStateAsStringFromUrl)
  const [searchStateAsString, setSearchStateAsString] = useState<string>(searchStateAsStringFromUrl)
  const [searchState, setSearchState] = useState<SearchState>(searchStateFromUrl)

  useEffect(() => {
    if (searchStateAsString !== searchStateAsStringFromUrl) {
      setSearchStateAsString(searchStateAsStringFromUrl)
      setSearchState(searchStateFromUrl)
    }
  }, [searchStateAsStringFromUrl, searchStateAsString, searchStateFromUrl])

  const noSearchMade = () => {
    const onlyDefaultFiltersApplied = Object.keys(searchState.filters).length === 1
    const noSearchStringEntered = searchState.searchString === ''
    return onlyDefaultFiltersApplied && noSearchStringEntered
  }

  return [searchState, noSearchMade]
}
