import { createContext, useContext } from 'react'

export interface User {
    username: string
    isStageRole: boolean
    isDeleteRole: boolean
}

const UserContext = createContext<User | undefined>(undefined)

export const useCurrentUser = (): User => {
  const possibleCurrentUser = useContext(UserContext)
  //possibleCurrentUser.isDeleteRole = true
  //possibleCurrentUser.isStageRole = true
  return possibleCurrentUser
}

export default UserContext
