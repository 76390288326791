import React from 'react'
import { FacetNames, FacetResponse, Filter, FilterOptions } from './api/filter'
import { SearchDocument } from './api/payloads'
import { SearchState, searchStateToString } from './api/searchState'
import {
  FACET_ITEMS_TO_ALWAYS_QUERY_FOR,
  NAVIGATION_FILTER_NAMES_IN_ORDER,
  numberToGetAllOptionsInFacets,
  ITEM_TYPE_MAPPING,
  EXTENSIONS_MAPPING,
  ICON_MAPPING,
} from './constants'
import { Buffer } from 'buffer'
import { Tooltip } from '@equinor/eds-core-react'

export const getFileIcon = (doc: { fileExtension: string, itemType: string }) => {
  const fileExtension = doc.fileExtension.toUpperCase()
  const itemtype = doc.itemType.toUpperCase()

  const addToolTip = (icon: JSX.Element, tooltip: string) => {
    return (
      <Tooltip title={tooltip} placement="right">
        <div>
          {icon}
        </div>
      </Tooltip>
    )
  }

  if (ITEM_TYPE_MAPPING[itemtype]) {
    return addToolTip(ICON_MAPPING[ITEM_TYPE_MAPPING[itemtype]], ITEM_TYPE_MAPPING[itemtype].toLowerCase() + " file")
  }

  for (const icon in EXTENSIONS_MAPPING) {
    if (EXTENSIONS_MAPPING[icon].includes(fileExtension)) {
      return addToolTip(ICON_MAPPING[icon], icon.toLowerCase() + " file")
    }
  }

  return addToolTip(ICON_MAPPING['DEFAULT'], fileExtension + " file")
}

export const delay = (delayTimeInMs: number) => {
  return new Promise(resolve => setTimeout(resolve, delayTimeInMs))
}

export const getFolderParts = (doc: SearchDocument): string[] => {
  const possibleFolderParts = [doc.project, doc.eRoom, doc.l0, doc.l1, doc.l2, doc.l3, doc.l4, doc.l5, doc.l6, doc.l7, doc.l8]
  const folderParts = []

  const levelExists = (index: number) => {
    return possibleFolderParts[index] !== ''
  }

  let i = 0
  while (i < possibleFolderParts.length && levelExists(i)) {
    folderParts.push(possibleFolderParts[i])
    i += 1
  }

  return folderParts
}

export const getLastLevel = (doc: SearchDocument): string => {
  const levels = getFolderParts(doc)
  return levels[levels.length - 1]
}

export const getAssociatedDbEntryName = (doc: SearchDocument): string => {
  const lastLevel = getLastLevel(doc)
  const secondUnderscoreIndex = lastLevel.split('_', 2).join('_').length
  return lastLevel.substring(secondUnderscoreIndex + 1)
}

export const getFilterFromDocPath = (folderParts: string[]): Filter => {
  return {
    project: folderParts[0] ? [folderParts[0]] : undefined,
    eRoom: folderParts[1] ? [folderParts[1]] : undefined,
    l0: folderParts[2] ?? undefined,
    l1: folderParts[3] ?? undefined,
    l2: folderParts[4] ?? undefined,
    l3: folderParts[5] ?? undefined,
    l4: folderParts[6] ?? undefined,
    l5: folderParts[7] ?? undefined,
    l6: folderParts[8] ?? undefined,
    l7: folderParts[9] ?? undefined,
    l8: folderParts[10] ?? undefined,
  }
}

export const setOtherFiltersToUndefined = (name: string, objectToRemoveFrom: { [key in FacetNames]?: any }) => {
  const index = NAVIGATION_FILTER_NAMES_IN_ORDER.findIndex(n => n === name)
  const newObject = {}

  if (index >= 0) {
    const laterNames = NAVIGATION_FILTER_NAMES_IN_ORDER.slice(index + 1)

    laterNames.forEach(laterName => {
      if (objectToRemoveFrom[laterName]) {
        newObject[laterName] = undefined
      }
    })
  }

  return newObject
}

export const selectAllFiltersBeforeAndSelfAndOneAfter = (name: string) => {
  const index = NAVIGATION_FILTER_NAMES_IN_ORDER.findIndex(k => k === name)
  const indexToSelectUntilInclusive = index + 1
  const newFacets = {}

  if (index >= 0) {
    const allNamesBeforeIndexAndOneAfter = NAVIGATION_FILTER_NAMES_IN_ORDER.slice(0, indexToSelectUntilInclusive + 1)

    allNamesBeforeIndexAndOneAfter.forEach(k => {
      newFacets[k] = numberToGetAllOptionsInFacets
    })
  }

  return newFacets
}

export const updatedNavigationSearchStatePart = (searchState: SearchState, filterName: string, newFilter: Filter) => {
  if (!NAVIGATION_FILTER_NAMES_IN_ORDER.includes(filterName) && filterName !== 'createDate') {
    return searchState
  }

  const { filters } = searchState
  if (filterName === 'createDate') {
    return {
      filters: {
        ...filters,
        ...newFilter,
      },
    }
  }

  return {
    filters: {
      ...filters,
      ...newFilter,
      ...setOtherFiltersToUndefined(filterName, filters),
    },
    facets: {
      ...FACET_ITEMS_TO_ALWAYS_QUERY_FOR,
      ...selectAllFiltersBeforeAndSelfAndOneAfter(filterName),
    },
  }
}

export const generateFilterOptions = (facetResponse: FacetResponse, filterOptions: FilterOptions, filters: Filter) => {
  const newFilterOptions = {}

  Object.keys(facetResponse).forEach(name => {
    newFilterOptions[name] = facetResponse[name].map(facet => {
      return { value: facet.value, label: facet.value + ' (' + facet.count + ')' }
    })
  })

  return newFilterOptions
}

export const createLabel = property => ({
  project: 'Select project',
  eRoom: 'Select eRoom',
}[property] || 'Select subfolder')

export const removeEroomFromName = (text: string): string => {
  const regex = /(?:.*?_){2}([\s\S]*)/
  const match = text.match(regex)
  return match ? match[1] : text
}

export const isNavigationFilter = (name: string) => {
  return NAVIGATION_FILTER_NAMES_IN_ORDER.includes(name)
}

export const removeFromFilter = (oldFilters: Filter, value: string, title: string) => {
  const newFilters = { ...oldFilters }
  const selectedValues = newFilters[title]

  if (Array.isArray(selectedValues)) {
    newFilters[title] = selectedValues.filter(filterValue => filterValue !== value)

    if (newFilters[title].length === 0) {
      delete newFilters[title]
    }
  } else {
    delete newFilters[title]
  }

  return newFilters
}

export const addToFilter = (filter: Filter, value: string, title: string) => {
  const newFilter = {
    ...filter,
    [title]: [...(filter[title] || []), value],
  }

  return newFilter
}

export const removeFiltersThatAreNoLongerInFilterOptions = (oldFilters: Filter, filterOptions: FilterOptions, filterName: string) => {
  const newFilter = { ...oldFilters }
  const filter = newFilter[filterName]

  if (Array.isArray(filter)) {
    return {
      ...newFilter,
      [filterName]: filter.filter(value => filterOptions[filterName].findIndex(option => option.value === value) > -1),
    }
  }
  return newFilter
}

export const removeMultipleFiltersThatAreNoLongerInFilterOptions = (oldFilters: Filter, filterOptions: FilterOptions, filterNames: string[]) => {
  let newFilter = { ...oldFilters }

  filterNames.forEach(filterName => {
    const filter = newFilter[filterName]

    if (Array.isArray(filter)) {
      newFilter = {
        ...newFilter,
        [filterName]: filter.filter(value => filterOptions[filterName].findIndex(option => option.value === value) > -1),
      }
    }
  })

  return newFilter
}


export const formatDate = (date: string): string => {
  const dateObj = new Date(date)
  const day = dateObj.getDate()
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()
  return `${day}.${month}.${year}`
}

export const formatBytes = (bytes: number): string => {
  const units = ['B', 'KB', 'MB', 'GB']
  let unitIndex = 0

  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024
    unitIndex++
  }

  return `${bytes.toFixed(2)} ${units[unitIndex]}`
}

export const extractNumberInParentheses = (input: string): string | null => {
  const regex = /\((\d+)\)$/ // Matches a number inside parentheses at the end of the string
  const match = input.match(regex)

  return match ? match[0] : null
}

export const extractStringWithoutParentheses = (input: string): string => {
  const regex = /^(.*?)\s\(\d+\)$/ // Matches the string followed by parentheses with a number at the end
  const match = input.match(regex)
  const result = match ? match[1] : input
  return result
}

export const getDirectoryUrl = (breadcrumbItems: string[], searchState: SearchState) => {

  const filterName = NAVIGATION_FILTER_NAMES_IN_ORDER[breadcrumbItems.length - 1]
  const breadcrumbItemsUpToAndIncludingThisIndex = breadcrumbItems.slice(0, breadcrumbItems.length)
  const filterForSearch = getFilterFromDocPath(breadcrumbItemsUpToAndIncludingThisIndex)
  const filter = updatedNavigationSearchStatePart(searchState, filterName, filterForSearch) as SearchState

  return '?' + searchStateToString(filter)
}

export const decodeBase64ToObject = (base64String: string) => {
  const decodedBase64 = Buffer.from(base64String, 'base64').toString('utf8')
  const decodedURI = decodeURIComponent(decodedBase64)

  return JSON.parse(decodedURI)
}

export const stripHTML = (str: string) => {
  const tmp = document.createElement('div')
  tmp.innerHTML = str

  return tmp.textContent || tmp.innerText
}

export const encodeObjectToBase64 = (object) => {
  const jsonString = JSON.stringify(object)
  const encodedURI = encodeURIComponent(jsonString)
  const encodedBase64 = Buffer.from(encodedURI, 'utf8').toString('base64')
  return encodedBase64
}

// inserts a space every 3 characters in a number
export const makeNumberReadable = (num: number): string => {
  if (num === undefined) {
    return ''
  }
  const strNum = num.toString()
  const reversedStr = strNum.split('').reverse().join('')
  let formattedStr = ''
  for (let i = 0; i < reversedStr.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      formattedStr += ' '
    }
    formattedStr += reversedStr[i]
  }
  return formattedStr.split('').reverse().join('')
}

export const removeFiltersFromSearchParams = (searchParams: any) => {
  const filtersToRemove = ['classification', 'creator', 'itemType', 'fileExtension']
  filtersToRemove.forEach(filter => {
    if (searchParams.filters[filter] !== undefined) {
      delete searchParams.filters[filter]
    }
  })

  return searchParams
}

export function startsWithDot(variable: string): boolean {
  return variable.startsWith('.')
}
export function removeParenthesesPart(inputString: string): string {
  return inputString.replace(/\s*\([^)]*\)/, '')
}

export function findDifferingElements(array1: string[], array2: string[]): string[] {
  const uniqueInArray1 = array1.filter(item => !array2.includes(item))
  const uniqueInArray2 = array2.filter(item => !array1.includes(item))
  return uniqueInArray1.concat(uniqueInArray2)
}

export function getBackEndCategoryName(category: string, value: string): string {
  if (category === 'classification') {
    return 'classification'
  }
  if (category === 'fileType') {
    if (startsWithDot(value)) {
      return 'fileExtension'
    } else {
      return 'itemType'
    }
  }
}

export const getPageNumber = (pageSize: number, itemIndex: number): number => {
  return Math.floor(itemIndex / pageSize)
}

export const shouldResetPagination = (prevState: SearchState, newState: SearchState): boolean => {
  const keysToCompare = ['searchString', 'filters', 'facets']

  for (const key of keysToCompare) {
    if (JSON.stringify(prevState[key]) !== JSON.stringify(newState[key])) {
      return true
    }
  }

  return false
}

export const bytesToMegabytes = (bytes: number): number => {
  return bytes / 1024 / 1024
}

export const generateZipName = (): string => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const hour = date.getHours()
  const minute = date.getMinutes()

  return `PIA export - ${day}.${month}.${year} - ${hour}.${minute}`
}

export const shortenFileName = (fileName: string, limit: number): string => {
  const extension = fileName.substring(fileName.lastIndexOf('.'))

  if (limit <= extension.length) {
    throw new Error("Limit is too small to preserve the file extension")
    return fileName
  }

  const mainPartMaxLength = limit - extension.length
  const mainPart = fileName.length > limit ? fileName.substring(0, mainPartMaxLength) : fileName.substring(0, fileName.lastIndexOf('.'))

  return mainPart + extension
}
