import React from 'react'

interface Props {
	size: number
}

export const WordIcon = ({ size }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.6414 9.94423H24.0956V12.8128H29.6414V9.94423Z" fill="#2E57A6" />
      <path d="M29.6414 15.6813H24.0956V18.5498H29.6414V15.6813Z" fill="#2E57A6" />
      <path d="M41.1155 21.4183H24.0956V24.0956H41.1155V21.4183Z" fill="#2E57A6" />
      <path d="M41.1155 26.9641H24.0956V29.6414H41.1155V26.9641Z" fill="#2E57A6" />
      <path d="M41.1155 32.51H24.0956V35.3785H41.1155V32.51Z" fill="#2E57A6" />
      <path d="M24.0956 38.247H41.1155V41.1155H24.0956V38.247Z" fill="#2E57A6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M43.6188 18.5498H29.5239V4.3984H12.7617V43.6016H43.6188V18.5498ZM29.5239 1.52988H33.3331H33.4287L46.4763 14.5339V18.5498H46.476V46.4701H9.90454V1.52988H29.5239ZM32.3811 4.61533V15.6813H43.5652L32.3811 4.61533Z" fill="#A6A6A6" />
      <path d="M1.52417 12.6215L21.1432 9.94423V40.8287L1.52417 37.8645V12.6215Z" fill="#2E57A6" />
      <path d="M19.0263 19.6942L16.1952 30.4064H13.5209L11.743 23.5339C11.6483 23.1753 11.5911 22.7744 11.5712 22.3312H11.5413C11.4965 22.8192 11.4317 23.2201 11.3471 23.5339L9.52434 30.4064H6.73799L3.92175 19.6942H6.55871L8.06767 26.8282C8.13241 27.132 8.17972 27.5403 8.2096 28.0533H8.25442C8.27434 27.6698 8.34655 27.2515 8.47106 26.7983L10.4133 19.6942H12.9979L14.7534 26.8879C14.8182 27.1519 14.8754 27.5354 14.9252 28.0383H14.9551C14.975 27.6449 15.0273 27.2465 15.112 26.8431L16.5911 19.6942H19.0263Z" fill="white" />
    </svg>
  )
}