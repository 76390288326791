import React from 'react'
import Article from '../Components/Article'
import { Table, Typography, List } from '@equinor/eds-core-react'

const KeyWordSearch = () => {
  return (
    <Article>
      <Article.Header>Keyword Search</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            In PIA the keyword search  works like a filter that you can apply to whatever folder
            you navigate too. This means that if you navigate to another folder, the search will
            persist unless you remove it by clicking "x" in the searchbar.
          </Typography>
          <Typography>
            There are a number of query syntaxes you can use to finetune your keyword search.
          </Typography>

        </Article.Section>

        <Article.Section>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                    Syntax
                </Table.Cell>
                <Table.Cell>
                    Example
                </Table.Cell>
                <Table.Cell>
                    Description
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                    Quotation marks
                </Table.Cell>
                <Table.Cell>
                  <span>"</span>Green Apple<span>"</span>
                </Table.Cell>
                <Table.Cell>
                   Search for results that contain the exact term “Green apple”
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                    Wildcard
                </Table.Cell>
                <Table.Cell>
                    Apple<span>*</span>
                </Table.Cell>
                <Table.Cell>
                    Search for words starting with Apple. i.e. Apples, Applepie, Applesauce.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                    NOT operator
                </Table.Cell>
                <Table.Cell>
                    Apples <span>-</span>green
                </Table.Cell>
                <Table.Cell>
                    Search for Apples, but exclude results containing green.
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                    OR operator
                </Table.Cell>
                <Table.Cell>
                    Apples <span>|</span> Pears
                </Table.Cell>
                <Table.Cell>
                    Search for Apples or Pears or both
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Article.Spacer />
          <Typography>
            For example: if you are not sure whether the phrase Facility or Facilities were used, you
            can use a truncation, Facilit*. If you know the exact title of a document or want a phrase
            to be written out exactly as you prefer it, you should use quotation marks.
          </Typography>
        </Article.Section>

      </Article.Body>
    </Article>
  )
}

export default KeyWordSearch