import React, { useState, useContext } from 'react'
import { Typography, Button, Dialog, Scrim, Icon } from '@equinor/eds-core-react'
import { SearchDocument } from '../../utils/api/payloads'
import SearchResultsTable from '../../components/SearchResultsTable'
import { useAppContext } from '../../utils/api/AppContext'
import { delay, getFilterFromDocPath, updatedNavigationSearchStatePart, makeNumberReadable } from '../../utils/helpers'
import { NAVIGATION_FILTER_NAMES_IN_ORDER } from '../../utils/constants'
import { useSearchStateContext } from '../../contexts/SearchStateContext'
import { useFilterOptionsContext } from '../../contexts/FilterOptionsContext'
import { ViewContext } from '../../contexts/ViewContext'
import styled from 'styled-components'
import { undo, delete_forever } from '@equinor/eds-icons'
import { Tooltip } from '@equinor/eds-core-react'
import TableHeader from '../../components/TableHeader'

const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 20px 20px 5px 20px;
background-color: white;
border-bottom: 1px solid #e0e0e0;
`

const Wrapper = styled.div`
    padding-left: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    p {
      white-space: nowrap;
      padding-right: 10px;
    }

    button {      
      height: 32px;
      border-radius: 7px;
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
    }
`
interface Props {
    documents: SearchDocument[]
    redoSearch: () => void
    children?: React.ReactNode
    isInAdmin: boolean
}

const StagedDocsView = ({ documents, redoSearch, children, isInAdmin }: Props) => {
  const { numTotalItems } = useContext(ViewContext)
  const { apiClient } = useAppContext()
  const { searchState, updateSearchState } = useSearchStateContext()
  const { setFilterOptions } = useFilterOptionsContext()

  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [docsChecked, setDocsChecked] = useState<SearchDocument[]>([])
  const gridRef = React.useRef<HTMLDivElement>(null)
  const isNoneSelected = docsChecked.length === 0

  const deleteDocuments = async (checkedDocs: SearchDocument[]) => {
    await apiClient.deleteMultipleDocuments(checkedDocs.map(doc => doc.indexKey))
    // Wait for the Azure search index to update before search
    await delay(1000)
    redoSearch()
  }

  const unStageDocuments = async (checkedDocs: SearchDocument[]) => {
    await apiClient.updateStageStatusMultiple(
      checkedDocs.map(doc => doc.indexKey),
      false
    )
    // Wait for the Azure search index to update before search
    await delay(500)
    redoSearch()
  }

  const onBreadcrumbClicked = (index: number, breadcrumbItems: string[]) => {
    const filterName = NAVIGATION_FILTER_NAMES_IN_ORDER[index]
    const breadcrumbItemsUpToAndIncludingThisIndex = breadcrumbItems.slice(0, index + 1)
    const filterForSearch = getFilterFromDocPath(breadcrumbItemsUpToAndIncludingThisIndex)

    setFilterOptions({})
    updateSearchState(updatedNavigationSearchStatePart(searchState, filterName, filterForSearch))
  }

  return (
    <Wrapper>
      <Header>
        <TableHeader isInAdmin={isInAdmin} searchState={searchState} />
        <ButtonContainer>
          <Tooltip
            title={
              isNoneSelected
                ? 'Select documents to restore from the table first'
                : 'restore selected documents'
            }>
            <Button
              variant='ghost'
              disabled={isNoneSelected}
              onClick={() => unStageDocuments(docsChecked)}
            >
              <Icon data={undo} size={18} />
              {`Restore ${docsChecked.length} documents`}
            </Button>
          </Tooltip>
          <Tooltip
            title={
              isNoneSelected
                ? 'Select documents to delete from the table first'
                : 'Delete selected documents permanently'
            }>
            <Button
              variant='outlined'
              color='danger'
              disabled={isNoneSelected}
              onClick={() => setShowDeleteWarning(true)}
            >
              <Icon data={delete_forever} size={18} />
              {`Delete ${docsChecked.length} documents`}
            </Button>
          </Tooltip>
        </ButtonContainer>
      </Header>
      {showDeleteWarning && (
        <Scrim onClose={() => setShowDeleteWarning(!showDeleteWarning)} open={showDeleteWarning}>
          <Dialog>
            <Dialog.Title>Confirm delete</Dialog.Title>
            <Dialog.Actions>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button color="danger" onClick={() => deleteDocuments(docsChecked)}>
                  {`Delete ${docsChecked.length} documents`}
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={() => setShowDeleteWarning(false)}>
                                    Close
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        </Scrim>
      )}
      <SearchResultsTable
        gridRef={gridRef}
        shouldStageDocs={false}
        documents={documents}
        docsChecked={docsChecked}
        setDocsChecked={setDocsChecked}
        onBreadcrumbClicked={onBreadcrumbClicked}
        isInAdmin={true}
      >
        {children}
      </SearchResultsTable>
    </Wrapper>
  )
}

export default StagedDocsView
