import React, { useCallback, useEffect, useState, useContext, useRef } from 'react'
import { useNavigate, Routes, Route } from 'react-router-dom'
import { useCurrentUser, User } from './utils/auth/UserContext'
import { SearchState, searchStateFromString, searchStateToString } from './utils/api/searchState'
import SearchStateContext from './contexts/SearchStateContext'
import { FilterOptions } from './utils/api/filter'
import FilterOptionsContext from './contexts/FilterOptionsContext'
import SearchRoute from './routes/Search/SearchRoute'
import GuideRoute from './routes/Guide/GuideRoute'
import { ITEMS_PER_PAGE } from './utils/constants'
import { generateFilterOptions, shouldResetPagination } from './utils/helpers'
import { SearchDocument } from './utils/api/payloads'
import { useAppContext } from './utils/api/AppContext'
import { ViewContext } from './contexts/ViewContext'
import { useUrlSearchState } from './utils/hooks'
import { SortOrder } from './utils/SortOrderEnum'


interface Props {
  isInAdmin: boolean
}

const Routing = ({ isInAdmin }: Props) => {
  const currentUser: User = useCurrentUser()
  const navigate = useNavigate()

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({})
  const [searchState, noSearchMade] = useUrlSearchState()
  const hasMounted = useRef(false)
  const { currentPage, searchString, filters, facets } = searchState
  const { errors, setErrors, setNumTotalItems, createdSort } = useContext(ViewContext)
  const { apiClient } = useAppContext()

  const [documents, setDocuments] = useState<SearchDocument[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [numDb, setNumDb] = useState<number>(undefined) // TODO: idk what this is for, find out and delete if not needed


  const updateSearchState = (statePart: Partial<SearchState>, isInAdmin: boolean) => {
    const newSearchState: SearchState = {
      ...searchState,
      ...statePart,
    }
    if (isInAdmin) {
      navigate(`/admin/?${searchStateToString(newSearchState)}`)
    } else {
      navigate(`/?${searchStateToString(newSearchState)}`)
    }
  }

  const clearSearch = (isInAdmin?: boolean, keepSearchString?: boolean) => {
    setFilterOptions({})

    const newSearchState = {
      filters: undefined,
      facets: {
        project: 0,
      },
      searchString: keepSearchString ? searchState.searchString : '',
    }

    updateSearchState(newSearchState, isInAdmin)
  }

  const updateSearchString = (text: string, isInAdmin?: boolean) => {
    const searchStatePart: Partial<SearchState> = {
      searchString: text,
      currentPage: 0,
    }
    updateSearchState(searchStatePart, isInAdmin)
  }

  //clear search state if admin mode changes
  useEffect(() => {
    if (hasMounted.current) {
      clearSearch(isInAdmin)
    } else {
      hasMounted.current = true
    }
  }, [isInAdmin])

  const fetchProjectOptions = useCallback(async () => {
    if (!filters.project) {
      const filterOutStagedDocs = {
        ...filters,
        isStaged: false,
      }

      return apiClient
        .searchWithFilter('*', 0, 0, filterOutStagedDocs, facets, 'Title asc')
        .then(res => {
          setFilterOptions({
            ...filterOptions,
            project: res.facets.project.map(project => {
              return { value: project.value, label: project.value + ' (' + project.count + ')' }
            }),
          })
        })
        .catch(e => {
          const error = {
            id: 'projectOptionsError',
            title: 'Failed to Fetch Project Options',
            body: 'An error occurred while fetching project options. Please try again later.',
            variant: 'danger',
          }
          console.error(e)
          setErrors({ ...errors, [error.id]: error })
        })
    }
  }, [apiClient])



  useEffect(() => {
    fetchProjectOptions()
  }, [fetchProjectOptions])

  //search for docs when search state changes
  useEffect(() => {
    if (!noSearchMade()) {
      searchForDocs(isInAdmin)
    }
  } , [searchState, createdSort])

  const searchForDocs = useCallback(
    (isInAdmin: boolean) => {
      setIsSearching(true)
      const searchQuery = searchString === '' ? '*' : searchString
      const start = currentPage * ITEMS_PER_PAGE
      const { filters, facets } = searchState
      const onlyStagedDocs = {
        ...filters,
        isStaged: isInAdmin,
      }

      apiClient
        .searchWithFilter(searchQuery, start, ITEMS_PER_PAGE, onlyStagedDocs, facets, createdSort === SortOrder.UNDEFINED ? 'Title asc' : `create_date ${createdSort}`)
        .then(res => {
          setDocuments(res.docs)
          setNumTotalItems(res.count)
          setFilterOptions(generateFilterOptions(res.facets, filterOptions, filters))
          if (res.facets.itemType) {
            setNumDb(res.facets.itemType.filter(a => a.value === 'db').map(b => b.count)[0])
          }
        })
        .catch(e => {
          setDocuments([])
          setNumTotalItems(undefined)
          setNumDb(undefined)
          const error = {
            id: 'searchError',
            title: 'Search Failed',
            body: 'An error occurred while performing the search. Please try again later.',
            variant: 'danger',
          }
          console.error(e)
          setErrors({ ...errors, [error.id]: error })
        })
        .finally(() => {
          setIsSearching(false)
        })
    },
    [apiClient, searchState, createdSort]
  )



  const decreasePage = (isInAdmin?: boolean) => {
    const searchStatePart: Partial<SearchState> = {
      currentPage: searchState.currentPage - 1,
    }
    updateSearchState(searchStatePart, isInAdmin)
  }

  const increasePage = (isInAdmin?: boolean) => {
    const searchStatePart: Partial<SearchState> = {
      currentPage: searchState.currentPage + 1,
    }
    updateSearchState(searchStatePart, isInAdmin)
  }

  useEffect(() => {
    const referredPage = sessionStorage.getItem('referredPage')

    if (referredPage) {
      const searchStatePart: Partial<SearchState> = {
        currentPage: parseInt(referredPage),
      }
      updateSearchState(searchStatePart, isInAdmin)
      sessionStorage.removeItem('referredPage')
    } else {
      const oldSearchStateString = sessionStorage.getItem('searchState')
      const oldSearchState = oldSearchStateString ? searchStateFromString(oldSearchStateString) : null

      if (oldSearchState && shouldResetPagination(searchState, oldSearchState) && searchState.currentPage !== 0) {
        const searchStatePart: Partial<SearchState> = {
          currentPage: 0,
        }
        updateSearchState(searchStatePart, isInAdmin)
      }

      sessionStorage.setItem('searchState', searchStateToString(searchState))
    }
  }, [searchState])

  return (
    <>
      <FilterOptionsContext.Provider value={{ filterOptions: filterOptions, setFilterOptions: setFilterOptions }}>
        <SearchStateContext.Provider value={{ searchState: searchState, updateSearchState: updateSearchState }}>
          <Routes>
            <Route
              path="/"
              element={
                <SearchRoute
                  decreasePage={decreasePage}
                  increasePage={increasePage}
                  clearSearch={clearSearch}
                  updateSearchString={updateSearchString}
                  documents={documents}
                  isSearching={isSearching}
                  searchForDocs={searchForDocs}
                  isInAdmin={false}
                />
              }
            />
            <Route
              path="/admin"
              element={
                currentUser.isDeleteRole ? (
                  <SearchRoute
                    decreasePage={decreasePage}
                    increasePage={increasePage}
                    clearSearch={clearSearch}
                    updateSearchString={updateSearchString}
                    documents={documents}
                    isSearching={isSearching}
                    searchForDocs={searchForDocs}
                    isInAdmin={true}
                  />
                ) : (
                  <div>You do not have the required role to visit the admin module</div>
                )
              }
            />
            <Route path="/guide" element={<GuideRoute />} />
            <Route path="/*" element={<div>This page does not exist</div>} />
          </Routes>
        </SearchStateContext.Provider>
      </FilterOptionsContext.Provider>
    </>
  )
}

export default Routing
