import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const HowAccessPia = () => {
  return (
    <Article>
      <Article.Header>How do I access PIA?</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            All equinor employes can access PIA by using the url pia.equinor.com.
            For more information, click <a href="https://statoilsrm.sharepoint.com/sites/DMT/SitePages/PIA---Project-Information-Archive.aspx#access-to-pia" target="_blank" rel="noreferrer">here</a>.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Restricted content in PIA</Article.SubHeader>
          <Typography>
            All employees have access to files with classification “Internal”. Files
            classified as “restricted” can be also be acccesed through AccessIT.
            Please request access to the corresponding project that the file is stored in.
          </Typography>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default HowAccessPia