import React, { useState } from 'react'
import { Button, Icon, Search } from '@equinor/eds-core-react'
import styled from 'styled-components'
import { search } from '@equinor/eds-icons'

const Grid = styled.div`
    background-color: white;
    padding: 20px 20px 20px 70px;
    width: 100%;
`

const SearchField = styled.div`
    width: 100%;
    max-width: 650px;
    display: grid;
    grid-template-columns: auto 60px;
    gap: 0px;
`

Icon.add({ search })

interface Props {
    placeholder: string
    onSearch: (searchString: string) => void
    value: string
}

const SearchBar = ({ placeholder, onSearch, value }: Props) => {
  const [searchString, setSearchString] = useState<string>(value)
  const search = () => onSearch(searchString)
  const handleSubmit = () => search()

  const handleChange = event => {
    setSearchString(event.target.value)
    if(event.target.value === '') {
      onSearch('')
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      search()
    }
  }

  return (
    <Grid>
      <SearchField>
        <Search id="search-normal" placeholder={placeholder} onChange={handleChange} onKeyPress={handleKeyPress} value={searchString} />
        <Button variant="ghost" type="submit" onClick={handleSubmit}>
          <Icon name="search" title="search" />
        </Button>
      </SearchField>
    </Grid>
  )
}

export default SearchBar
