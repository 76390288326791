import React from 'react'
import styled from 'styled-components'
import { Button } from '@equinor/eds-core-react'
import { Dialog } from '@equinor/eds-core-react'


const Modal = styled(Dialog)`
 min-width: 800px;
 max-height: 80vh;
  overflow: auto;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    p {
        white-space: nowrap;
        padding-right: 10px;
    }
    
    button {      
        height: 42px;
        border-radius: 7px;
        white-space: nowrap;
    }
`

interface Props {
    okText: string
    cancelText: string
    title: string
    children: React.ReactNode
    onOk: () => void
    onCancel: () => void
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

const ModalContainer = ({ okText, cancelText, title, children, onOk, onCancel, isOpen, setIsOpen }: Props) => {

  return <>
    <Modal open={isOpen} isDismissable onClose={setIsOpen}>
      <Dialog.Header>
        <Dialog.Title>{title}</Dialog.Title>
      </Dialog.Header>
      <Dialog.CustomContent>
        {children}
      </Dialog.CustomContent>
      <Dialog.Actions>
        <Wrapper>
          <Button onClick={onOk}>
            {okText}
          </Button>
          <Button variant="ghost" onClick={onCancel}>
            {cancelText}
          </Button>
        </Wrapper>
      </Dialog.Actions>
    </Modal>
  </>
}

export default ModalContainer