import React, { useState, useEffect, useContext } from 'react'
import { Typography, Button, Icon } from '@equinor/eds-core-react'
import styled, { css } from 'styled-components'
import { download } from '@equinor/eds-icons'
import {
  formatBytes,
  formatDate,
  removeEroomFromName,
  decodeBase64ToObject,
  getFolderParts,
  getDirectoryUrl,
  removeFiltersFromSearchParams
} from '../../../utils/helpers'
import { useUrlSearchState } from '../../../utils/hooks'

import Resize from '../Buttons/Resize'
import { ViewContext } from '../../../contexts/ViewContext'
import { useAppContext } from '../../../utils/api/AppContext'
import { SearchDocument } from '../../../utils/api/payloads'
import SisterFileView from './SisterFileView'
import Header from './Header'
import DetailsTable from './DetailsTable'

const MIN_WIDTH = 400
const MAX_WIDTH = 1500

interface ContainerProps {
    width: number
}

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: 1px solid #e0e0e0;
    min-width: ${MIN_WIDTH}px;
    max-width: ${MAX_WIDTH}px;
    ${({ width }) =>
    css`
            width: ${width}px;
        `}
`
const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
`
const FileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding: 15px;
    background-color: #ffffff;

    button {
        height: 32px;
    }
`
const FolderHeader = styled.div`
    padding-top: 20px;
    padding: 15px;
`

interface Props {
    selectedDoc: SearchDocument
    width: number
    setWidth: (width: number) => void
    closeSideSheet: () => void
}



export const DetailsSideBar = ({ selectedDoc, width, setWidth, closeSideSheet }: Props) => {
  const [searchState] = useUrlSearchState()

  const { isSideSheetOpen, errors, setErrors } = useContext(ViewContext)
  const { apiClient } = useAppContext()

  const [foldersPathArray, setFoldersPathArray] = useState<string[]>([])
  const [files, setFiles] = useState<SearchDocument[]>([])
  const [folderInfo, setFolderInfo] = useState<string[]>()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [folderIndex, setFolderIndex] = useState<number>(foldersPathArray && foldersPathArray.length - 1)

  useEffect(() => {
    if (selectedDoc) {
      setFoldersPathArray(getFolderParts(selectedDoc))
      setFolderIndex(getFolderParts(selectedDoc).length - 1)
    }
  }, [selectedDoc])

  // handles the folder navigation in sister file view & when a new file is selected
  useEffect(() => {
    if (foldersPathArray.length > 0) {
      getFilesInFolder()
    }
  }, [foldersPathArray, folderIndex])

  useEffect(() => {
    let isMounted = true
    if (selectedDoc) {
      const dbEntry = files.find(doc => {
        return doc.name === 'dbentry.json' && doc.title !== '' && foldersPathArray[folderIndex].includes(doc.title)
      })

      if (dbEntry) {
        apiClient
          .fetchJSON(dbEntry.indexKey)
          .then(response => isMounted && setFolderInfo(response))
          .catch(error => console.error(error))
      } else if (isMounted) {
        setFolderInfo(null)
      }
    }

    return () => {
      isMounted = false
    }
  }, [foldersPathArray, folderIndex, files])

  // gets the files inside the selected documents folder
  const getFilesInFolder = () => {
    if (foldersPathArray.length > 0) {
      setFiles([])

      const currentFolderPath = foldersPathArray.slice(0, folderIndex + 1)
      let searchParams = decodeBase64ToObject(getDirectoryUrl(currentFolderPath, searchState).substring(1))

      searchParams = removeFiltersFromSearchParams(searchParams)

      apiClient.searchWithFilter('*', 0, 5000, searchParams.filters, searchParams.facets, 'Title asc')
        .then(response => {
          setFiles(response.docs)
        })
    }
  }
  if (!isSideSheetOpen) return null
  if (!selectedDoc) return null

  const downloadFile = (selectedDoc: SearchDocument) => {
    setIsDownloading(true)

    apiClient.fetchJSON(selectedDoc.indexKey).finally(() => {
      setIsDownloading(false)
    })

    apiClient
      .downloadFile(selectedDoc.indexKey, selectedDoc.name, selectedDoc.contentType)
      .catch(e => {
        const newError = {
          id: 'downloadError',
          title: 'Error when downloading',
          body: 'Please apply for access to this project',
          variant: 'danger',
        }
        console.error(e)
        setErrors({ ...errors, [newError.id]: newError })
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }

  return (
    <Container width={width}>
      <Resize orientation="left" minWidth={MIN_WIDTH} maxWidth={MAX_WIDTH} width={width} setWidth={setWidth} />
      {selectedDoc && (
        <>
          <Header closeSideSheet={closeSideSheet} selectedDoc={selectedDoc} />
          <Content id="contextView">
            <DetailsTable
              color="#ffffff"
              info={{
                Title: removeEroomFromName(selectedDoc.title),
                Name: removeEroomFromName(selectedDoc.name),
                'File size': formatBytes(selectedDoc.size),
                Created: formatDate(selectedDoc.createDate),
                Creator: selectedDoc.creator,
              }}
              moreInfo={{
                Project: selectedDoc.project,
                eRoom: selectedDoc.eRoom,
                'File name': selectedDoc.name,
                'File extension': selectedDoc.fileExtension,
                Company: selectedDoc.Company,
                Contractor: selectedDoc.ContractorName,
                'Document ID': selectedDoc.Uniquedocid,
                'Revision number': selectedDoc.contentrevno,
                'Revision status': selectedDoc.contentRevStatus,
                'Archive status': selectedDoc.contentstatus,
              }}
            >
              <FileHeader>
                <Typography variant="h4">File properties</Typography>
                <Button
                  onClick={async () => {
                    downloadFile(selectedDoc)
                  }}
                  disabled={isDownloading}
                >
                  <Icon data={download} size={20} /> Download File </Button>
              </FileHeader>
            </DetailsTable>

            {folderInfo && (
              <DetailsTable color="#F7F7F7" info={folderInfo}>
                <FolderHeader>
                  <Typography variant="h4">Folder properties</Typography>
                </FolderHeader>
              </DetailsTable>
            )}

            <SisterFileView
              folders={foldersPathArray}
              index={folderIndex}
              // setIndex={setFolderIndex}
              document={selectedDoc}
              documents={files}
              getFilesInFolder={getFilesInFolder}
            />
          </Content>
        </>
      )}
    </Container>
  )
}

export default DetailsSideBar
