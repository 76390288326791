import React from 'react'
import {
  image,
  email,
  info_circle,
  file,
  zip_file,
  movie_file,
  comment_discussion,
  external_link,
  file_description,
} from '@equinor/eds-icons'
import { ExcelIcon } from '../assets/icons/ExcelIcon'
import { PdfIcon } from '../assets/icons/PdfIcon'
import { PowerPointIcon } from '../assets/icons/PowerPointIcon'
import { WordIcon } from '../assets/icons/WordIcon'
import { FileIcon } from '../assets/icons/FileIcon'
import { Icon } from '@equinor/eds-core-react'

export const numberOfOptionsToFetch = 10
export const numberToGetAllOptionsInFacets = 0
export const NAVIGATION_FILTER_NAMES_IN_ORDER = ['project', 'eRoom', 'l0', 'l1', 'l2', 'l3', 'l4', 'l5', 'l6', 'l7', 'l8']
export const ITEMS_PER_PAGE = 100
export const MAX_DOWNLOAD_SIZE = 500 // MB
export const DEFAULT_SHEET_WIDTH = 500
export const FACET_ITEMS_TO_ALWAYS_QUERY_FOR = {
  classification: numberOfOptionsToFetch,
  creator: numberOfOptionsToFetch,
  fileExtension: numberOfOptionsToFetch,
  itemType: numberOfOptionsToFetch,
}

export const ICON_SIZE = 24
export const ICON_MAPPING = {
  PDF: <PdfIcon size={ICON_SIZE} />,
  WORD: <WordIcon size={ICON_SIZE} />,
  EXCEL: <ExcelIcon size={ICON_SIZE} />,
  POWERPOINT: <PowerPointIcon size={ICON_SIZE} />,
  IMAGE: <Icon data={image} color={'grey'} />,
  EMAIL: <Icon data={email} color={'grey'} />,
  INFO: <Icon data={info_circle} color={'grey'} />,
  ZIP: <Icon data={zip_file} color={'grey'} />,
  MEDIA: <Icon data={movie_file} color={'grey'} />,
  DWG: <FileIcon size={ICON_SIZE} fontColor={'black'} text={'DWG'} fillColor={'#fecf20'} />,
  DXF: <FileIcon size={ICON_SIZE} fontColor={'white'} text={'DXF'} fillColor={'#0B90FF'} />,
  DGN: <FileIcon size={ICON_SIZE} fontColor={'white'} text={'DGN'} fillColor={'black'} />,
  MESSAGE: <Icon data={comment_discussion} color={'grey'} />,
  LINK: <Icon data={external_link} color={'grey'} />,
  NOTE: <Icon data={file_description} color={'grey'} />,
  DEFAULT: <Icon data={file} color={'grey'} />,
}
export const EXTENSIONS_MAPPING = {
  PDF: ['.PDF'],
  WORD: ['.DOC', '.DOCX'],
  EXCEL: ['.XLSX', '.XLSM', '.XLSB', '.XLTX', '.XLTM', '.XLS', '.XLT', '.XML', '.XLAM', '.XLA', '.XLW', '.XLR', '.CSV'],
  POWERPOINT: ['.PPTX', '.PPTM', '.PPT', '.POTX', '.POTM', '.POT', '.PPSX', '.PPSM', '.PPS', '.PPAM', '.PPA'],
  IMAGE: ['.TIF', '.TIFF', '.JPG', '.JPEG', '.PNG', '.BMP'],
  EMAIL: ['.MSG'],
  INFO: ['.JSON'],
  ZIP: ['.ZIP'],
  DWG: ['.DWG'],
  DXF: ['.DXF'],
  DGN: ['.DGN'],
  MEDIA: ['.GIF', '.MP4', '.MKV', '.MNG'],
}
export const ITEM_TYPE_MAPPING = {
  MESSAGE : 'MESSAGE',
  DISCUSSION: 'MESSAGE',
  TOPIC: 'MESSAGE',
  LINK: 'LINK',
  NOTE: 'NOTE',
  EMAIL: 'MAILPAGE',
  INFO: 'DBENTRY',
  // DB: 'DB' samme ikon som info
}
