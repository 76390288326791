import { createContext, useContext } from 'react'

export type ErrorObject = {
    originalError: Error
    title?: string
    suggestedAction?: string
}

type ErrorContextType = {
    error?: ErrorObject
    setError?: (error: ErrorObject) => void
}

const ErrorContext = createContext<ErrorContextType>({})

export const useErrorContext = (): ErrorContextType => {
  const errorContext = useContext(ErrorContext)
  return errorContext
}

export default ErrorContext
