import React, { useContext, useEffect } from 'react'
import { ViewContext } from '../../../contexts/ViewContext'
import styled from 'styled-components'
import { useFilterOptionsContext } from '../../../contexts/FilterOptionsContext'
import { updatedNavigationSearchStatePart, getFilterFromDocPath, extractStringWithoutParentheses, removeEroomFromName } from '../../../utils/helpers'
import { useSearchStateContext } from '../../../contexts/SearchStateContext'
import FolderElement from './FolderElement'
import { Input, Button, Icon, Tooltip } from '@equinor/eds-core-react'
import { search, close } from '@equinor/eds-icons'

const Level = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const SearchFieldContainer = styled.div`
  padding: 10px 0;
`

const FolderList = styled.div`
    padding-left: 15px;
    border-left: 1px solid #e0e0e0;
`

interface FolderHierarchyProps {
  fetchedNavigationFilters: string[]
  isInAdmin: boolean
}

const FolderHierarchy: React.FC<FolderHierarchyProps> = ({ fetchedNavigationFilters, isInAdmin }) => {
  const { setFilterOptions } = useFilterOptionsContext()
  const { filePath, currentLevel, setCurrentLevel } = useContext(ViewContext)
  const { searchState, updateSearchState } = useSearchStateContext()
  const [visibleChildFolders, setVisibleChildFolders] = React.useState<string[]>(filePath[filePath.length - 1])
  const [showSearch, setShowSearch] = React.useState<boolean>(false)

  function extractStrings(arr) {
    const result = []
    for (let i = 0; i < arr.length - 1; i++) {
      if (Array.isArray(arr[i]) && arr[i].length > 0) {
        result.push(extractStringWithoutParentheses(arr[i][0]))
      }
    }
    return result
  }

  // uses the index of the clicked folder to update the search state
  const updateSearchUsingIndex = (breadcrumbItems: string[], name: string, index) => {
    setCurrentLevel(name)

    const breadcrumbItemsUpToAndIncludingThisIndex = breadcrumbItems.slice(0, index + 1)
    const filterForSearch = getFilterFromDocPath(breadcrumbItemsUpToAndIncludingThisIndex)
    setFilterOptions({})
    updateSearchState(updatedNavigationSearchStatePart(searchState, name, filterForSearch), isInAdmin)
  }

  const handleClickOnFolder = (folder: string, folders: string[][], index: number) => {
    const clickedFolderName = fetchedNavigationFilters[index]
    const currentFolderNameIndex = fetchedNavigationFilters.indexOf(currentLevel)
    const clickedFolderNameIndex = fetchedNavigationFilters.indexOf(clickedFolderName)

    if (clickedFolderNameIndex === currentFolderNameIndex) {
      return
    }

    const breadcrumbItems = [...extractStrings(folders), extractStringWithoutParentheses(folder)].filter(
      (item, pos, self) => self.indexOf(item) === pos
    )

    updateSearchUsingIndex(breadcrumbItems, clickedFolderName, index)
  }


  useEffect(() => {
    setVisibleChildFolders(filePath[filePath.length - 1])
    setShowSearch(false)
  }, [filePath])

  const REVERSED_FILTER_LEVELS = ['l8', 'l7', 'l6', 'l5', 'l4', 'l3', 'l2', 'l1', 'l0', 'eRoom', 'project']

  const determineActiveFilterLevel = () =>
    REVERSED_FILTER_LEVELS.find(level => searchState.filters[level] !== undefined)

  useEffect(() => {
    const activeFilterLevel = determineActiveFilterLevel()
    setCurrentLevel(activeFilterLevel)
  }, REVERSED_FILTER_LEVELS.map(level => searchState.filters[level]))

  const filterFolders = (searchString: string) => {
    const childFolders = filePath[filePath.length - 1]
    const filteredFolders = childFolders.filter(folder => folder.toLowerCase().includes(searchString.toLowerCase()))
    setVisibleChildFolders(filteredFolders)
  }

  const renderFolderHierarchy = (index = 0): JSX.Element | null => {
    if (index >= filePath.length) return null
    if (filePath.length === 1) return null

    const isLastIteration = index === filePath.length - 1

    return (
      <FolderList key={index}>
        {isLastIteration && showSearch && (
          <SearchFieldContainer>
            <Input
              placeholder="Search"
              onChange={e => filterFolders(e.target.value)}
              autoFocus
            />
          </SearchFieldContainer>
        )}

        {(!isLastIteration || filePath.length < 2) && filePath[index].map((folder, i) => (
          <Level key={i}>
            <FolderElement
              name={removeEroomFromName(folder)}
              onClick={() => handleClickOnFolder(folder, filePath, index)}
            />
            {filePath.length - 2 === index &&
              <div>
                <Tooltip
                  title={showSearch ? 'Close search' : 'Search in this folder'}
                  placement="top">
                  <Button
                    variant="ghost_icon"
                    onClick={() => {
                      setShowSearch(!showSearch)
                      setVisibleChildFolders(filePath[filePath.length - 1])
                    }}
                  >
                    <Icon size="20" data={showSearch ? close : search} />
                  </Button>
                </Tooltip>
              </div>
            }
          </Level>
        ))}

        {(isLastIteration && visibleChildFolders) && visibleChildFolders.map((folder, i) => (
          <FolderElement
            name={removeEroomFromName(folder)}
            onClick={() => handleClickOnFolder(folder, filePath, index)}
            key={i}
          />
        ))}

        {renderFolderHierarchy(index + 1)}
      </FolderList>
    )
  }

  return renderFolderHierarchy()
}

export default FolderHierarchy
