import React from 'react'
import { Button } from '@equinor/eds-core-react'
import styled from 'styled-components'

const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    //background-color: #f7f7f7;
`

interface Props {
    currentPage: number
    numPages: number
    onPrevClick: () => void
    onNextClick: () => void
}

const Pagination = ({ currentPage, numPages, onPrevClick, onNextClick }: Props) => {
  const isPrevDisabled = currentPage <= 1
  const isNextDisabled = currentPage >= numPages

  return (
    <PaginationContainer id="pagination">
      <div>
        <Button onClick={onPrevClick} disabled={isPrevDisabled}>
                    Previous
        </Button>
      </div>
      <div>
        <p>
          {currentPage} / {numPages}
        </p>
      </div>
      <div>
        <Button onClick={onNextClick} disabled={isNextDisabled}>
                    Next
        </Button>
      </div>
    </PaginationContainer>
  )
}

export default Pagination
