import React from 'react'
import TableBreadCrumbs from '../../Breadcrumbs/TableBreadcrumbs'
import { SearchDocument } from '../../../utils/api/payloads'
import { getFolderParts } from '../../../utils/helpers'

interface BreadcrumbsCellProps {
  documents: SearchDocument[];
  rowIndex: number;
  shouldStageDocs: boolean;
  onBreadcrumbClicked?: (index: number, breadcrumbItems: string[]) => void;
}

const BreadcrumbsCell: React.FC<BreadcrumbsCellProps> = ({
  documents,
  rowIndex,
  shouldStageDocs,
  onBreadcrumbClicked
}) => {
  const breadcrumbItems = getFolderParts(documents[rowIndex])

  const handleBreadcrumbClick = (index: number) => {
    if (onBreadcrumbClicked) {
      onBreadcrumbClicked(index, breadcrumbItems)
    }
  }

  return (
    <TableBreadCrumbs
      breadcrumbItems={breadcrumbItems}
      shouldCollapse={shouldStageDocs}
      onBreadcrumbClicked={handleBreadcrumbClick}
    />
  )
}

export default BreadcrumbsCell
