import React from 'react'
import { close } from '@equinor/eds-icons'
import { removeEroomFromName } from '../../../utils/helpers'
import { getFileIcon } from '../../../utils/helpers'
import { Typography, Button, Icon, Tooltip } from '@equinor/eds-core-react'
import styled from 'styled-components'

const Header = styled.div`
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
`
const HeaderTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    max-width: calc(100% - 50px); 

    div {
      position: relative;
      top: 2px;
    }
`
const HeaderText = styled(Typography)`
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    max-width: 100%;
    white-space: nowrap;
`
const CloseIcon = styled(Button)`
    position: relative;
    top: 6px;
`

interface DocumentHeaderProps {
    selectedDoc: any
    closeSideSheet: () => void
}

const DocumentHeader = ({ selectedDoc, closeSideSheet }: DocumentHeaderProps) => {

  return (
    <Header>
      <Tooltip title={selectedDoc.name} placement="left">
        <HeaderTitle>
          <div>{getFileIcon(selectedDoc)}</div>
          <HeaderText variant="h3">{selectedDoc.title ? selectedDoc.title : removeEroomFromName(selectedDoc.name)}</HeaderText>
        </HeaderTitle>
      </Tooltip>
      <CloseIcon variant="ghost_icon" onClick={() => closeSideSheet()}>
        <Icon data={close} size={28} />
      </CloseIcon>
    </Header>
  )
}


export default DocumentHeader
