import React, { useState, ReactNode } from 'react'
import { Table, Button, Icon, EdsProvider } from '@equinor/eds-core-react'
import { chevron_up, chevron_down } from '@equinor/eds-icons'
import styled from 'styled-components'

interface StyledProps {
    bgColor: string
}

const StyledTable = styled(Table)<StyledProps>`
    width: 100%;
    background-color: ${props => props.bgColor};
    padding: 0 15px;
    
`
const EndCell = styled(Table.Cell)`
    text-align: end;
    white-space: normal;
    word-break: break-word;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 5px;
`
const ShowMore = styled.div<StyledProps>`
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e0e0e0;
    padding: 8px 0;
    background-color: ${props => props.bgColor};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 15px;
`

const StartCell = styled(Table.Cell)`
    color: #8C8C8C;
    white-space: nowrap;
    padding-left: 5px;

`

interface DetailsTableProps {
    info: { [key: string]: any }
    moreInfo?: { [key: string]: any }
    children?: ReactNode
    color: string
}

const DetailsTable: React.FC<DetailsTableProps> = ({ info, moreInfo, children, color }) => {
  const [showingMore, setShowingMore] = useState<boolean>(false)

  return (
    <>
      <EdsProvider density="compact">
        <StyledTable bgColor={color}>
          <Table.Caption>{children}</Table.Caption>
          <Table.Body>

            {Object.entries(info).map(
              ([key, value]) =>
                key !== 'Change log' && (
                  <Table.Row key={key}>
                    <StartCell>{key}</StartCell>
                    <EndCell>{value}</EndCell>
                  </Table.Row>
                )
            )}

            {showingMore && moreInfo && (
              <>
                {Object.entries(moreInfo).map(([key, value]) => (
                  <Table.Row key={key}>
                    <StartCell>{key}</StartCell>
                    <EndCell>{value}</EndCell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
        </StyledTable>
      </EdsProvider>
      {moreInfo && (
        <ShowMore bgColor={color}>
          <Button
            variant="ghost"
            onClick={() => {
              setShowingMore(!showingMore)
            }}
          >
            {showingMore ? <Icon data={chevron_up} /> : <Icon data={chevron_down} />}
            {showingMore ? 'Show less' : 'Show more'}
          </Button>
        </ShowMore>
      )}
    </>
  )
}

export default DetailsTable
