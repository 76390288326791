import React from 'react'
import Template from './Template'

const February2024 = () => {
  const title = 'February 2024'
  const updates = [
    "Admin page design update",
    "Search for folder names in folder navigation sidebar",
    "AgGrid table implemented",
    "Filtering options moved from sidebars to table headers",
    "Navigation bar redesign",
    "Button toolbar redesign",
    "Added additional file icons and descriptions",
    "Improved responsivity to smaller screen sizes",
    "Added user guide page",
    "Add certain data from folder properties to index, making it easier to search for specific data",
    "Bulk download to download multiple files in a single zip file",
    "Download folder as zip available from the sidesheet",
    "Ability to sort on created date by clicking on the column header"
  ]


  return <Template title={title} updateList={updates} />
}

export default February2024