import React, { useState, useEffect } from 'react'
import { Typography } from '@equinor/eds-core-react'
import styled from 'styled-components'
import { ServiceMessage } from '../components/ServiceMessage'
import { useAppContext } from '../utils/api/AppContext'

const IntroContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    align-items: left;
    padding: 20px 40px;
    max-width: 900px;

    h2 {
        margin-bottom: 20px;
        font-weight: 600;
    }
`

const IntroText = styled(Typography)`
    font-size: 16px;
    line-height: 1.5;
    color: #444;
    margin-bottom: 20px;

    br {
        margin-bottom: 20px;
    }
`

const LinkText = styled.a`
    color: #337ab7;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

type IntroComponentProps = {
    serviceMessage?: string
}

const IntroComponent: React.FC<IntroComponentProps> = () => {
  const [serviceMessage, setServiceMessage] = useState<string>()

  const { apiClient } = useAppContext()
  useEffect(() => {
    let isMounted = true
    apiClient.getServiceMessage().then(res => {
      if (isMounted) {
        setServiceMessage(res)
      }
    })
    return () => {
      isMounted = false
    }
  }, [apiClient])

  /*
    Project Information Archive (PIA) contains all information and data from eRoom Classic. eRoom was the main collaboration solution in projects from 2007 until around 2017 and was used both for project internal collaboration and contracts. From 2017 it was gradually phased out and exchanged with Pims DC and SharePoint Online. In 2021 eRoom was closed and its entire content were migrated to PIA.

All employees have access to information classified as Internal. Additional access can be requested through AccessIT. For more information about search, navigation, and access, open the User Guide from the top bar.

If you think the information, you are looking for is subject to export control, please read more information in the User Guide.
  */


  return (
    <IntroContainer>
      {serviceMessage && serviceMessage !== '' && <ServiceMessage message={serviceMessage} />}
      <Typography variant="h2">Welcome to PIA – Project Information Archive!</Typography>
      <IntroText>
        Project Information Archive (PIA) contains all information and data from eRoom Classic. eRoom was the main collaboration
        solution in projects from 2007 until around 2017 and was used both for project internal collaboration and contracts. From
        2017 it was gradually phased out and exchanged with Pims DC and SharePoint Online. In 2021 eRoom was closed and its entire
        content were migrated to PIA.
      </IntroText>
      <IntroText>
        All employees have access to information classified as Internal. Additional access can be requested through AccessIT. For
        more information about search, navigation, and access, open the User Guide from the top bar.
      </IntroText>
      <IntroText>
        If you think the information, you are looking for is subject to export control, please read more information in the User Guide.
      </IntroText>
    </IntroContainer>
  )
}

export default IntroComponent
