import { api_root } from '../../config'
import { SearchDocument, SearchResults } from './payloads'
import { FacetRequest, Filter } from './filter'
import { shortenFileName } from '../helpers'

export class APIClient {
  getAccessToken: Function

  constructor(getAccessToken: Function) {
    this.getAccessToken = getAccessToken
  }

  private async query(method: 'GET' | 'POST' | 'DELETE', path: string, body?: Object): Promise<Response> {
    const tok = await this.getAccessToken()

    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${tok}`,
    }

    const init: RequestInit = {
      method,
      headers,
    }
    if (body !== undefined) {
      init.body = JSON.stringify(body)
    }

    const url = `${api_root}/${path}`
    return fetch(url, init).then(response => {
      if (response.ok) {
        return response
      } else {
        return response.json().then(errorObject => {
          throw new Error(errorObject.title)
        })
      }
    })
  }

  private async GET(path: string): Promise<Response> {
    return this.query('GET', path)
  }

  private async POST(path: string, body?: Object): Promise<Response> {
    return this.query('POST', path, body)
  }

  private async DELETE(path: string, body?: Object): Promise<Response> {
    return this.query('DELETE', path, body)
  }

  async getServiceMessage(): Promise<string> {
    const path = 'Notification/serviceMessage'
    return (await this.GET(path)).json()
  }

  async searchWithFilter(
    query: string,
    begin: number,
    amount: number,
    filters: Filter,
    facets: FacetRequest,
    orderby?: string
  ): Promise<SearchResults> {
    const path = `AzureSearch/query/${query}?begin=${begin}&amount=${amount}`
    return (await this.POST(path, { filters: filters, facets: facets, orderby: orderby })).json()
  }

  async getDocument(indexKey: string): Promise<SearchDocument> {
    return (await this.GET(`AzureSearch/doc/${indexKey}`)).json()
  }

  async updateStageStatus(indexKey: string, newStageStatus: boolean): Promise<void> {
    const path = `UpdateStageStatus/${indexKey}?isStaged=${newStageStatus}`
    await this.POST(path)
    return
  }

  async updateStageStatusMultiple(indexKeys: string[], newStageStatus: boolean): Promise<void> {
    const promises: Promise<void>[] = indexKeys.map(key => this.updateStageStatus(key, newStageStatus))
    return Promise.all(promises).then()
  }

  async deleteDocument(indexKey: string): Promise<void> {
    const path = `DeleteDocument/${indexKey}`
    await this.DELETE(path)
    return
  }

  async deleteMultipleDocuments(indexKeys: string[]): Promise<void> {
    const promises: Promise<void>[] = indexKeys.map(key => this.deleteDocument(key))
    return Promise.all(promises).then()
  }

  async downloadMultiple(postBody: any): Promise<any> {
    try {
      const response = await this.POST('downloadmultiple', postBody)
      if (response.ok) {
        const blob = await response.blob()
        return this.downloadBlob(blob, postBody.ZipFileName + '.zip', 'application/zip')
      } else {
        const errorText = await response.json()
        throw new Error(errorText.title)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async downloadFile(key: string, name: string, mimeType: string): Promise<void> {
    const tok = await this.getAccessToken()
    return fetch(`${api_root}/Download/${key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${tok}`,
      },
    }).then(response => {
      if (response.ok) {
        return response.blob().then((blob: Blob) => {
          this.downloadBlob(blob, name, mimeType)
        })
      } else {
        return response.json().then(errorText => {
          throw new Error(errorText.title)
        })
      }
    })
  }

  async fetchJSON(key: string) {
    try {
      const tok = await this.getAccessToken()
      const response = await fetch(`${api_root}/Download/${key}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${tok}`,
        },
      })

      if (response.ok) {
        const json = await response.json()
        return json
      } else {
        throw new Error('Failed to fetch JSON file')
      }
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  async displayFile(key: string): Promise<any> {
    const tok = await this.getAccessToken()

    return fetch(`${api_root}/Download/${key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${tok}`,
      },
    }).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        return response.json().then(errorText => {
          throw new Error(errorText.title)
        })
      }
    })
  }

  downloadBlob(blob: Blob, name: string, mimeType: string): void {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = window.URL.createObjectURL(new Blob([blob], { type: mimeType }))
    a.download = shortenFileName(name, 100)
    a.click()
    window.URL.revokeObjectURL(a.href)
  }
}
