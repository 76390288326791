import { createContext, useContext } from 'react'
import { FilterOptions } from '../utils/api/filter'

type FilterOptionsContextType = {
    filterOptions?: FilterOptions
    setFilterOptions?: (filterOptions: FilterOptions) => void
}

const FilterOptionsContext = createContext<FilterOptionsContextType>({})

export const useFilterOptionsContext = (): FilterOptionsContextType => useContext(FilterOptionsContext)

export default FilterOptionsContext
