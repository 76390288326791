import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Article from "../../components/Guide/Components/Article"
import Header from "../../components/Guide/Components/Header"
import { Link } from "react-router-dom"
import { tokens } from '@equinor/eds-tokens'
import WhatIsPia from "../../components/Guide/Articles/WhatIsPia"
import WhoToContact from "../../components/Guide/Articles/WhoToContact"
import ExportControlDisclaimer from "../../components/Guide/Articles/ExportControlDisclaimer"
import HowAccessPia from "../../components/Guide/Articles/HowAccessPia"
import KeyWordSearch from "../../components/Guide/Articles/KeywordSearch"
import FolderNavigationInfo from "../../components/Guide/Articles/FolderNavigationInfo"
import FilterAndSort from "../../components/Guide/Articles/FilterAndSort"
import FileSideBar from "../../components/Guide/Articles/FileSideBar"
import DownloadInfo from "../../components/Guide/Articles/DownloadInfo"
import EroomJsonFiles from "../../components/Guide/Articles/EroomJsonFiles"
import GdprViolations from "../../components/Guide/Articles/GdprViolations"
import KnownIssues from "../../components/Guide/Articles/KnownIssues"
import February2024 from "../../components/Guide/Articles/Updates/February2024"


const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  padding: 40px;
    background-color: white;
`

const ArticleView = styled.div`
  margin-top: 20px;
  max-width: 1000px;
`

const MainHeader = styled.div`
  font-family: Equinor;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 30px;
`
const NavHeader = styled.div`
  margin-top: 20px;
  font-family: Equinor;
  font-weight: 600;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    color: ${tokens.colors.interactive.primary__resting.rgba};
  }
`
const Button = styled.button<{ isActive: boolean }>`
  font-family: Equinor;
  transition: all 0.3s ease-in-out;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 4px solid ${({ isActive }) => (isActive ? tokens.colors.interactive.primary__resting.rgba : '#e6e6e6')};
  background-color: transparent;
  display: flex;
  padding: 10px 20px;
  padding-left: ${({ isActive }) => (isActive ? '26px' : '20px')}; 
  width: 100%;

  &:hover {
    border-left: 4px solid ${tokens.colors.interactive.primary__resting.rgba};
  }

  &:focus {
    outline: none;
  }
`

const NavigationBar = styled.div`
  position: sticky;
  margin-top: 20px;
  top: 20px;
  min-width: 270px;
`

const placeholder = (title: string) => {
  const PlaceholderComponent: React.FC = () => {
    return (
      <Article>
        <Article.Header>{title}</Article.Header>
        <Article.Body>Coming soon...</Article.Body>
      </Article>
    )
  }

  PlaceholderComponent.displayName = `Placeholder(${title})`

  return PlaceholderComponent
}

const articles = [
  { title: "What is PIA", component: () => <WhatIsPia /> },
  { title: "How do I access PIA?", component: () => <HowAccessPia /> },
  { type: "header", title: "How do I find content?", component: () => <Header>How do I find content?</Header> },
  { title: "Keyword search", component: () => <KeyWordSearch /> },
  { title: "Folder navigation", component: () => <FolderNavigationInfo /> },
  { title: "Filter and sort", component: () => <FilterAndSort /> },
  { title: "File side sheet", component: () => <FileSideBar /> },
  { type: "header", title: "How do I view content?", component: () => <Header>How do I view content?</Header> },
  { title: "Download", component: () => <DownloadInfo /> },
  { title: "eRoom JSON files", component: () => <EroomJsonFiles /> },
  { title: "Export Control Disclaimer", component: () => <ExportControlDisclaimer /> },
  { type: "header", title: "Support", component: () => <Header>Support</Header> },
  { title: "Reporting GDPR violations", component: () => <GdprViolations /> },
  { title: "Known issues and workarounds", component: () => <KnownIssues /> },
  { title: "Who can I contact?", component: () => <WhoToContact /> },
  { type: "header", title: "Release notes", component: () => <Header>Release notes</Header> },
  { title: "February 2024", component: () => <February2024 /> },
]

const GuideRoute = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [activeArticleIndex, setActiveArticleIndex] = useState(0)
  const articleRefs = useRef<(React.RefObject<HTMLDivElement> | null)[]>([])

  useEffect(() => {
    articleRefs.current = Array(articles.length).fill(null).map((_, i) => articleRefs.current[i] ?? React.createRef())
    setIsLoading(false)
  }, [articles])

  const handleScroll = () => {
    const newActiveIndex = articleRefs.current.findIndex((ref) => {
      if (!ref.current) return false
      const rect = ref.current.getBoundingClientRect()
      return rect.top >= 0 && rect.top < window.innerHeight / 2
    })

    if (newActiveIndex !== -1) {
      setActiveArticleIndex(newActiveIndex)
    }
  }

  useEffect(() => {
    if (isLoading) { return }
    const hash = window.location.hash.substring(1)
    const decodedHash = decodeURIComponent(hash)
    const articleIndex = articles.findIndex(a => a.title === decodedHash)

    if (articleIndex !== -1 && articleRefs.current[articleIndex].current) {
      scrollToArticle(articleRefs.current[articleIndex], articleIndex)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isLoading])

  const scrollToArticle = (ref: React.RefObject<HTMLDivElement>, index: number) => {
    setActiveArticleIndex(index)
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <Wrapper>
      <NavigationBar>
        <MainHeader>User Guide</MainHeader>
        {articles.map((article, index) => (
          article.type !== 'header' ?
            <Link to={`/guide#${article.title}`} key={index}>
              <Button
                key={index}
                isActive={index === activeArticleIndex}
                onClick={() => scrollToArticle(articleRefs.current[index], index)}
              >
                {article.title}
              </Button>
            </Link> :
            <NavHeader
              key={index}
              onClick={() => scrollToArticle(articleRefs.current[index], index)}
            >
              {article.title}
            </NavHeader>

        ))}
      </NavigationBar>
      <ArticleView>
        {articles.map((Article, index) => (
          <div key={index} ref={articleRefs.current[index]}>
            <Article.component />
          </div>
        ))}
      </ArticleView>
    </Wrapper>
  )
}

export default GuideRoute