import React from "react"
import { Button } from "@equinor/eds-core-react"
import ExportIcon from "../../../assets/icons/ExportIcon"

interface Props {
    gridRef: any
}

const ExportToExcelButton = ({ gridRef }: Props) => {
  const exportToExcel = () => {
    gridRef.current.api.exportDataAsExcel()
  }

  return (
    <Button variant="ghost" onClick={exportToExcel}>
      <ExportIcon size={19} color="#006F78" />
        Export to Excel
    </Button>
  )
}

export default ExportToExcelButton