import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const GdprViolations = () => {
  return (
    <Article>
      <Article.Header>Reporting GDPR violations</Article.Header>
      <Article.Body>
        <Article.Section>
          <Typography>
            If you have the administrator role in PIA, you are able to report GDPR violations found in files.
            You can do this by clicking the “Report GDPR violations” button. This will hide the files and add
            them to a list of files with reported GDPR violations.
          </Typography>
          <Article.Gallery>
            <img src="/media/articleImages/report.png" alt="Report GDPR violations" />
          </Article.Gallery>
          <Article.Gallery>
            <img src="/media/articleImages/stage.png" alt="Report GDPR violations" />
          </Article.Gallery>
        </Article.Section>
        <Article.Spacer />
        <Article.Section>
          <Article.SubHeader>Administrating reported files</Article.SubHeader>
          <Typography>
            As an administrator you can view this list
            in the page “Reported files”. This page is simillar to the regular search page, but only shows files
            where a GDPR violation has been reported. Here you have the option to permanently delete files from
             PIA or restore them.
          </Typography>
          <Article.Gallery>
            <img src="/media/articleImages/administrate.png" alt="Reported files" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default GdprViolations