import React from 'react'

interface Props {
	size: number
}
export const ExcelIcon = ({size}: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M43.6188 18.5498H29.5239V4.39841H12.7617V43.6016H43.6188V18.5498ZM29.5239 1.52988H33.3331H33.4287L46.4763 14.5339V18.5498H46.476V46.4701H9.90454V1.52988H29.5239ZM32.3811 4.61533V15.6813H43.5652L32.3811 4.61533Z" fill="#A6A6A6" />
      <path d="M1.52417 12.6215L21.1432 9.94422V40.8287L1.52417 37.8645V12.6215Z" fill="#1D7849" />
      <path d="M17.984 32.3187H14.2288L11.8071 27.74C11.7156 27.5701 11.6208 27.2563 11.5228 26.7988H11.4836C11.4378 27.0145 11.3299 27.3413 11.16 27.7792L8.72848 32.3187H4.95374L9.43441 25.2889L5.33611 18.259H9.18929L11.1992 22.475C11.3561 22.8083 11.4966 23.2038 11.6208 23.6613H11.66C11.7385 23.3868 11.8855 22.9783 12.1012 22.4358L14.3367 18.259H17.8663L13.6504 25.23L17.984 32.3187Z" fill="white" />
      <path d="M29.6414 9.94422H24.0956V12.8127H29.6414V9.94422Z" fill="#1D7849" />
      <path d="M29.6414 15.6813H24.0956V18.5498H29.6414V15.6813Z" fill="#1D7849" />
      <path d="M33.8486 21.4183H41.1155V24.0956H33.8486V21.4183Z" fill="#1D7849" />
      <path d="M24.0956 21.4183H31.1713V24.0956H24.0956V21.4183Z" fill="#1D7849" />
      <path d="M31.1713 26.9641H24.0956V29.6414H31.1713V26.9641Z" fill="#1D7849" />
      <path d="M24.0956 32.51H31.1713V35.3785H24.0956V32.51Z" fill="#1D7849" />
      <path d="M33.8486 35.3785V32.51H41.1155V35.3785H33.8486Z" fill="#1D7849" />
      <path d="M24.0956 38.247H31.1713V41.1155H24.0956V38.247Z" fill="#1D7849" />
      <path d="M33.8486 41.1155H41.1155V38.247H33.8486V41.1155Z" fill="#1D7849" />
      <path d="M33.8486 26.9641V29.6414H41.1155V26.9641H33.8486Z" fill="#1D7849" />
    </svg>
  )
}