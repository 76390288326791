import React from 'react'
import { Typography } from '@equinor/eds-core-react'
import styled from 'styled-components'

const SidebarHeader = styled(Typography)`
    font-weight: 600;
`

interface SidebarHeaderProps {
    currentLevel: string
}


const Header: React.FC<SidebarHeaderProps> = ({ currentLevel }) => {
  const lowerCaseLevel = currentLevel.toLowerCase()
  const levelToTitle = {
    l: 'Folder selection',
    eroom: 'Folder selection',
    project: 'eRoom selection',
    dropdown: 'Project selection',
  }
  const title = levelToTitle[lowerCaseLevel] || levelToTitle[lowerCaseLevel[0]]

  return title ? <SidebarHeader variant="h6">{title}</SidebarHeader> : null
}

export default Header
