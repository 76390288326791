import React, { useContext } from 'react'
import { Typography } from '@equinor/eds-core-react'
import ProjectBreadCrumbs from './Breadcrumbs/ProjectBreadCrumbs'
import { makeNumberReadable, removeEroomFromName } from '../utils/helpers'
import { ViewContext } from '../contexts/ViewContext'
import { useUrlSearchState } from '../utils/hooks'
import styled from 'styled-components'

const ResultsInfo = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
  flex-grow: 1;
`

type HeaderProps = {
  isInAdmin: boolean;
  searchState: {
    searchString: string;
  };
};

const TableHeader: React.FC<HeaderProps> = ({ isInAdmin, searchState }) => {
  const { filePath, numTotalItems } = useContext(ViewContext)
  const [, noSearchMade] = useUrlSearchState()

  const getHeaderContent = () => {
    if (searchState.searchString) {
      return (
        <Typography variant="h3">
          Search Results for "{searchState.searchString}" ({makeNumberReadable(numTotalItems)})
        </Typography>
      )
    } else if (!searchState.searchString && filePath[filePath.length - 2]?.length > 0) {
      return (
        <Typography variant="h3">
          {removeEroomFromName(filePath[filePath.length - 2][0])}
        </Typography>
      )
    } else if (!searchState.searchString && noSearchMade()) {
      return (
        <Typography variant="h3">
          {isInAdmin ? `Reported files (${numTotalItems})` : 'Home'}
        </Typography>
      )
    }
  }

  return (
    <ResultsInfo>
      {getHeaderContent()}
      <ProjectBreadCrumbs isInAdmin={isInAdmin} />
    </ResultsInfo>
  )
}

export default TableHeader
