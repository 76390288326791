/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, ReactNode, useEffect } from 'react'
import { SearchDocument } from '../utils/api/payloads'
import { SortOrder } from '../utils/SortOrderEnum'

export const ViewContext = createContext({
  isSideSheetOpen: false,
  setIsSideSheetOpen: (isSideSheetOpen: boolean) => {},
  sideBarIsOpen: false,
  setSideBarIsOpen: (sideBarIsOpen: boolean) => {},
  crumbElement: null,
  setCrumbElement: (crumbElement: any) => {},
  sideBarMode: null,
  setSideBarMode: (sideBarMode: string) => {},
  lastSideBarMode: null,
  setLastSideBarMode: (sideBarMode: string) => {},
  errors: {},
  setErrors: (errors: object) => {},
  numTotalItems: undefined,
  setNumTotalItems: (numTotalItems: number) => {},
  filePath: [],
  setFilePath: (filePath: any[]) => {},
  currentLevel: '',
  setCurrentLevel: (currentLevel: string) => {},
  levelText: '',
  setLevelText: (levelText: string) => {},
  selectedDoc: null,
  setSelectedDoc: (selectedDoc: SearchDocument) => {},
  downloadMode: false,
  setDownloadMode: (downloadMode: boolean) => {},
  stageMode: false,
  setStageMode: (stageMode: boolean) => {},
  createdSort: SortOrder.UNDEFINED,
  setCreatedSort: (createdSort: SortOrder ) => {},
})

interface ViewContextProviderProps {
    children: ReactNode
}

export const ViewContextProvider: React.FC<ViewContextProviderProps> = ({ children }) => {
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false)
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false)
  const [crumbElement, setCrumbElement] = useState(null)
  const [sideBarMode, setSideBarMode] = useState('navigation')
  const [lastSideBarMode, setLastSideBarMode] = useState('start')
  const [errors, setErrors] = useState({})
  const [numTotalItems, setNumTotalItems] = useState(undefined)
  const [filePath, setFilePath] = useState([])
  const [selectedDoc, setSelectedDoc] = useState<SearchDocument | null>(null)
  const [downloadMode, setDownloadMode] = useState(sessionStorage.getItem('downloadMode') === 'true' ? true : false)
  const [stageMode, setStageMode] = useState(sessionStorage.getItem('stageMode') === 'true' ? true : false)
  const [currentLevel, setCurrentLevel] = useState<string>(() => {
    const savedLevel = sessionStorage.getItem('currentLevel')
    return savedLevel ? savedLevel : ''
  })
  const [levelText, setLevelText] = useState('')
  const [createdSort, setCreatedSort] = useState(SortOrder.UNDEFINED)

  useEffect(() => {
    sessionStorage.setItem('currentLevel', currentLevel)
  }
  , [currentLevel])

  useEffect(() => {
    sessionStorage.setItem('stageMode', JSON.stringify(stageMode))
  } , [stageMode])

  useEffect(() => {
    sessionStorage.setItem('downloadMode', JSON.stringify(downloadMode))
  } , [downloadMode])

  const value = {
    isSideSheetOpen,
    setIsSideSheetOpen,
    sideBarIsOpen,
    setSideBarIsOpen,
    crumbElement,
    setCrumbElement,
    sideBarMode,
    setSideBarMode,
    lastSideBarMode,
    setLastSideBarMode,
    errors,
    setErrors,
    numTotalItems,
    setNumTotalItems,
    filePath,
    setFilePath,
    currentLevel,
    setCurrentLevel,
    levelText,
    setLevelText,
    selectedDoc,
    setSelectedDoc,
    downloadMode,
    setDownloadMode,
    stageMode,
    setStageMode,
    createdSort,
    setCreatedSort,
  }

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>
}
