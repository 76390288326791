import React from 'react'
import Article from '../Components/Article'
import { Typography } from '@equinor/eds-core-react'

const GdprViolations = () => {
  return (
    <Article>
      <Article.Header>Known issues and workarounds</Article.Header>
      <Article.Body>
        <Article.Section>
          <Article.SubHeader>Search filtered to only show dbEntries</Article.SubHeader>
          <Typography>
            In the first version of PIA the search would automatiaclly filter to dbEntry. This feature was removed with
            in the summer of 2023. If you still run into this issue, it could be because you are using an old url.
            If this is the case, the problem should be fixed by removing the filter and using the new url.
          </Typography>
        </Article.Section>
        <Article.Section>
          <Article.SubHeader>Error message when downloading files</Article.SubHeader>
          <Typography>
             Sometimes you get an error message trying to download a file, normally .msg-file (Outlook email),
             saying the file type could potentially harm your computer. This is a security measure from corporate
             security that we cannot do anything about. But there is a work around. Browse the curser over the file
             in the download window in your web browser. An ellipsis (three dot) menu should occur, similar to the
             ellipsis menu in SharePoint. Click on that and choose "keep", and the file will be downloaded.
          </Typography>
          <Article.Gallery >
            <img src="/media/articleImages/errorOnDownloadFile.png" alt="how to handle file download error" />
          </Article.Gallery>
        </Article.Section>
      </Article.Body>
    </Article>
  )
}

export default GdprViolations